import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Testimonial } from './types';
import { logout } from 'features/auth/slice';

interface State {
  testimonialToDelete: Testimonial | null;
}

const initialState: State = {
  testimonialToDelete: null,
};

const testimonialsSlice = createSlice({
  name: 'testimonials',
  initialState,
  reducers: {
    setTestimonialToDelete: (state, action: PayloadAction<{ testimonial: Testimonial }>) => {
      state.testimonialToDelete = action.payload.testimonial;
    },
    resetTestimonialToDelete: (state) => {
      state.testimonialToDelete = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const { setTestimonialToDelete, resetTestimonialToDelete } = testimonialsSlice.actions;
export const { reducer } = testimonialsSlice;
