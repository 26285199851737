import React, { FC } from 'react';
import NetworkImage from './NetworkImage';

interface Props {
  src: string;
  label: string;
}

const ImageOption: FC<Props> = (props) => {
  const { src, label } = props;

  return (
    <div className='image-option'>
      <NetworkImage className='me-2' src={src} alt={label} />
      <span>{label}</span>
    </div>
  );
};

export default ImageOption;
