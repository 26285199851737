import { useAppSelector } from 'core/redux-store';
import { useQuery } from 'react-query';
import { ShoppingItemsClient } from 'api/clients';
import { mapShoppingItem } from '../map';
import { ShoppingItemsFilter } from 'api/clients/shopping-items/types';
import { selectShoppingItemsLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (filter: ShoppingItemsFilter, lastUpdated: number) => {
  return ['shopping-items', filter, lastUpdated];
};

const useShoppingItems = (filter: ShoppingItemsFilter = {}) => {
  const lastUpdated = useAppSelector(selectShoppingItemsLastUpdated);
  const getShoppingItemsFn = () => ShoppingItemsClient.getShoppingItems({ filter });
  const { isLoading, data } = useQuery(getQueryKey(filter, lastUpdated), getShoppingItemsFn);

  return {
    isLoading,
    shoppingItems: data?.data.map(mapShoppingItem) ?? [],
    shoppingItemsCount: data?.total ?? 0,
  };
};

export default useShoppingItems;
