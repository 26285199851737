import httpClient from '../../httpClient';
import { DataResponse } from '../../types';
import { WeeklyPlan, SaveWeeklyPlanRequest } from './types';

const baseUrl = '/admin/weekly-plans';

export const getWeeklyPlanByOrderNumber = (number: string) => {
  return httpClient.get<void, DataResponse<WeeklyPlan>>(`${baseUrl}/${number}`);
};

export const addWeeklyPlan = async (request: SaveWeeklyPlanRequest) => {
  return httpClient.post<SaveWeeklyPlanRequest, DataResponse<WeeklyPlan>>(baseUrl, request);
};
