import { FC } from 'react';
import { CellProps } from 'react-table';
import { Subscription } from '../types';
import { SubscriptionType } from 'api/clients/subscriptions/types';
import { useSubscriptionsTranslations } from '../hooks';

const TypeCell: FC<CellProps<Subscription, SubscriptionType>> = (props) => {
  const { value: type } = props;
  const { t } = useSubscriptionsTranslations();
  return <>{t(type)}</>;
};

export default TypeCell;
