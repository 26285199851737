import { Testimonial, SaveTestimonialRequest } from './types';
import httpClient from '../../httpClient';
import { DataResponse, ListResponse } from '../../types';

const baseUrl = '/admin/testimonials';

export const getTestimonials = () => {
  return httpClient.get<void, ListResponse<Testimonial>>(baseUrl);
};

export const getTestimonial = (id: number) => {
  return httpClient.get<void, DataResponse<Testimonial>>(`${baseUrl}/${id}`);
};

export const addTestimonial = async (request: SaveTestimonialRequest) => {
  return httpClient.post<SaveTestimonialRequest, DataResponse<Testimonial>>(baseUrl, request);
};

export const editTestimonial = async (id: number, request: SaveTestimonialRequest) => {
  return httpClient.put<SaveTestimonialRequest, DataResponse<Testimonial>>(
    `${baseUrl}/${id}`,
    request
  );
};

export const deleteTestimonial = (id: number) => {
  return httpClient.delete(`${baseUrl}/${id}`);
};
