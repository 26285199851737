import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { RevisionForm } from 'features/revisions/hoc';
import { useOrder } from 'features/orders/hooks';
import { RevisionOrder } from 'features/orders/types';
import { useRevision } from 'features/revisions/hooks';

const RevisionDetailsPage: FC = () => {
  const params = useParams();
  const orderNumber = params.number as string;
  const { order, isLoading: isOrderLoading } = useOrder(orderNumber);
  const { revision, isLoading: isRevisionLoading } = useRevision(orderNumber);

  return (
    <Container>
      {!!order && !isOrderLoading && !isRevisionLoading && !!revision && (
        <RevisionForm revision={revision ?? undefined} revisionOrder={order as RevisionOrder} />
      )}
    </Container>
  );
};

export default RevisionDetailsPage;
