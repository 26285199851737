import { useAppDispatch, useAppSelector } from 'core/redux-store';
import { useMutation } from 'react-query';
import { ProductsClient } from 'api/clients';
import { resetProductToDelete } from '../slice';
import { showSuccessToast } from 'core/toast';
import useProductsTranslations from './useProductsTranslations';
import { useNavigate } from 'react-router-dom';
import { selectProductToDelete } from '../selectors';
import { updateProducts } from 'core/redux-store/global/slice';

const useProductDelete = () => {
  const { t } = useProductsTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const productToDelete = useAppSelector(selectProductToDelete);

  const { mutateAsync: deleteProduct, isLoading } = useMutation(
    async () => (productToDelete ? ProductsClient.deleteProduct(productToDelete.id) : undefined),
    {
      onSuccess: () => {
        dispatch(updateProducts());
        dispatch(resetProductToDelete());
        showSuccessToast(t('product_is_successfully_deleted'));
        navigate('/products');
      },
    }
  );

  return { isLoading, deleteProduct };
};

export default useProductDelete;
