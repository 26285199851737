import React, { FC } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  content?: string;
  isActionsDisabled?: boolean;
  confirmLabel: string;
  cancelLabel?: string;
  onConfirm: () => void;
}

const ConfirmationModal: FC<Props> = (props) => {
  const {
    isVisible,
    onClose,
    title,
    isActionsDisabled = false,
    confirmLabel,
    cancelLabel,
    onConfirm,
    content,
  } = props;

  const { t } = useTranslation('common');

  return (
    <Modal show={isVisible} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      {content && <Modal.Body>{`"${content}"`}</Modal.Body>}
      <Modal.Footer>
        <Button
          size='sm'
          onClick={onClose}
          disabled={isActionsDisabled}
          variant='outline-secondary'
        >
          {cancelLabel ?? t('cancel')}
        </Button>
        <Button size='sm' onClick={onConfirm} disabled={isActionsDisabled} variant='danger'>
          {confirmLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
