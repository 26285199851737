import React, { FC, useCallback } from 'react';
import browseIllustration from 'assets/svg/browse.svg';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { fileToBase64String } from '../utils/file';
import { Base64File } from 'api/types';

interface Props {
  onAdd: (value: Base64File[]) => void;
  multiple?: boolean;
  className?: string;
}

const Dropzone: FC<Props> = (props) => {
  const { onAdd, className, multiple = true } = props;
  const { t } = useTranslation('dropzone');

  const onDrop = useCallback(
    async (files: File[]) => {
      const base64Files: Base64File[] = [];

      for (const file of files) {
        base64Files.push({
          value: await fileToBase64String(file),
          name: file.name,
          size: file.size,
        });
      }

      onAdd(base64Files);
    },
    [onAdd]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple });

  return (
    <div className={classNames('dz-dropzone dz-dropzone-card', className)} {...getRootProps()}>
      <input {...getInputProps()} />
      <div className='dz-message'>
        <img
          className='avatar avatar-xl avatar-4x3 mb-3'
          src={browseIllustration}
          alt='Browse illustration'
        />
        <h5>{t('drag_and_drop_files_here')}</h5>
        <p className='mb-2'>{t('or')}</p>
        <Button variant='white' size='sm'>
          {t('browse_files')}
        </Button>
      </div>
    </div>
  );
};

export default Dropzone;
