import React, { FC, useCallback } from 'react';
import { useColorDelete, useColorsTranslations } from '../hooks';
import { useAppSelector } from 'core/redux-store';
import { selectColorToDelete } from '../selectors';
import { resetColorToDelete } from '../slice';
import { useDispatch } from 'react-redux';
import { ConfirmationModal } from 'components';

const ColorDeleteConfirmation: FC = () => {
  const { t } = useColorsTranslations();
  const dispatch = useDispatch();

  const { deleteColor, isLoading } = useColorDelete();
  const colorToDelete = useAppSelector(selectColorToDelete);

  const handleOnConfirmationModalClose = useCallback(() => {
    dispatch(resetColorToDelete());
  }, [dispatch]);

  return (
    <ConfirmationModal
      isActionsDisabled={isLoading}
      isVisible={!!colorToDelete}
      content={colorToDelete?.title}
      onClose={handleOnConfirmationModalClose}
      title={t('are_you_sure_want_to_delete_this_color')}
      confirmLabel={t('delete')}
      onConfirm={deleteColor}
    />
  );
};

export default ColorDeleteConfirmation;
