import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { RevisionForm } from 'features/revisions/hoc';
import { useOrder } from 'features/orders/hooks';
import { RevisionOrder } from 'features/orders/types';

const RevisionAddPage: FC = () => {
  const params = useParams();
  const orderNumber = params.number as string;
  const { order, isLoading } = useOrder(orderNumber);

  return (
    <Container>
      {!!order && !isLoading && <RevisionForm revisionOrder={order as RevisionOrder} />}
    </Container>
  );
};

export default RevisionAddPage;
