import React, { FC } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { useOneTimerForm, useOneTimersTranslations } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { DetailsForm, MetaForm, MediaForm } from '../components';
import { OneTimerOrder } from 'features/orders/types';
import { OneTimer } from '../types';

interface Props {
  oneTimerOrder: OneTimerOrder;
  oneTimer?: OneTimer;
}

const OneTimerForm: FC<Props> = (props) => {
  const { oneTimerOrder, oneTimer } = props;
  const { t } = useOneTimersTranslations();
  const navigate = useNavigate();

  const { control, handleSubmit, save, isLoading } = useOneTimerForm(
    oneTimerOrder.number,
    oneTimer
  );

  return (
    <>
      <div className='page-header'>
        <Row className='align-items-center'>
          <Col>
            <h2 className='page-header-title'>
              {oneTimer ? t('one_timer_details') : t('add_one_timer')}
            </h2>
          </Col>
          <Col lg='auto'>
            <Button
              size='sm'
              onClick={() => navigate('/orders')}
              disabled={isLoading}
              variant='white'
            >
              {t('back_to_orders_list')}
            </Button>
            {!oneTimer && (
              <>
                <Button
                  size='sm'
                  className='ms-2'
                  onClick={() => navigate('/shopping-items/add')}
                  variant='white'
                >
                  {t('add_shopping_item')}
                </Button>
                <Button
                  className='ms-2'
                  size='sm'
                  onClick={handleSubmit(save)}
                  disabled={isLoading}
                  variant='primary'
                >
                  {t('save_one_timer')}
                </Button>
              </>
            )}
          </Col>
        </Row>
      </div>
      <Row className='mb-3'>
        <Col lg={8}>
          <DetailsForm
            isDisabled={!!oneTimer}
            oneTimerOrder={oneTimerOrder}
            className='mb-3'
            control={control}
          />
          <MediaForm oneTimer={oneTimer} control={control} />
        </Col>
        <Col lg={4}>
          <MetaForm oneTimerOrder={oneTimerOrder} />
        </Col>
      </Row>
    </>
  );
};

export default OneTimerForm;
