import axios from 'axios';
import { store } from 'core/redux-store';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { logout } from 'features/auth/slice';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

httpClient.interceptors.request.use(
  async (request) => {
    const token = store.getState().auth.token;

    if (token) {
      request.headers = request.headers ?? {};
      request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
  },
  (error) => Promise.reject(error)
);

const refreshAuthLogic = async () => {
  store.dispatch(logout());
  return Promise.reject();
};

createAuthRefreshInterceptor(httpClient, refreshAuthLogic);
httpClient.interceptors.response.use(async (response) => response.data);

export default httpClient;
