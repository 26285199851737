import { useAppSelector } from 'core/redux-store';
import { useQuery } from 'react-query';
import { RevisionPlansClient } from 'api/clients';
import { mapRevisionPlan } from '../map';
import { selectRevisionPlansLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (lastUpdated: number) => {
  return ['revision-plans', lastUpdated];
};

const useRevisionPlans = () => {
  const lastUpdated = useAppSelector(selectRevisionPlansLastUpdated);

  const { isLoading, data } = useQuery(
    getQueryKey(lastUpdated),
    RevisionPlansClient.getRevisionPlans
  );

  return {
    isLoading,
    revisionPlans: data?.data.map(mapRevisionPlan) ?? [],
    revisionPlansCount: data?.total ?? 0,
  };
};

export default useRevisionPlans;
