import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from './types';
import { logout } from 'features/auth/slice';

interface State {
  userToDelete: User | null;
}

const initialState: State = {
  userToDelete: null,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserToDelete: (state, action: PayloadAction<{ user: User }>) => {
      state.userToDelete = action.payload.user;
    },
    resetUserToDelete: (state) => {
      state.userToDelete = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const { setUserToDelete, resetUserToDelete } = usersSlice.actions;
export const { reducer } = usersSlice;
