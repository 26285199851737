import React, { FC } from 'react';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useOneTimersTranslations } from '../hooks';
import { CurrencySelect, FormGroup } from 'components';
import { OneTimerOrder } from 'features/orders/types';
import { useOrdersTranslations } from '../../orders/hooks';
import { Link } from 'react-router-dom';

interface Props {
  oneTimerOrder: OneTimerOrder;
  className?: string;
}

const MetaForm: FC<Props> = (props) => {
  const { oneTimerOrder, className } = props;
  const { t } = useOneTimersTranslations();
  const { t: ordersT } = useOrdersTranslations();

  return (
    <div className={className}>
      <Card className='mb-4'>
        <Card.Header>
          <h5 className='card-header-title'>{t('order_details')}</h5>
        </Card.Header>
        <Card.Body>
          <Row className='mb-3'>
            <Col>
              <FormGroup label={t('user')}>
                <Link to={`/users/details/${oneTimerOrder.user.id}`}>
                  {oneTimerOrder.user.email}
                </Link>
              </FormGroup>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <FormGroup label={t('order_number')}>
                <Form.Control disabled value={oneTimerOrder.number} type='text' />
              </FormGroup>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <FormGroup label={t('status')}>
                <Form.Control disabled value={ordersT(oneTimerOrder.status)} type='text' />
              </FormGroup>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <FormGroup label={t('price')}>
                <InputGroup>
                  <Form.Control value={oneTimerOrder.price.toFixed(2)} disabled type='number' />
                  <CurrencySelect isReadOnly value={oneTimerOrder.currency} />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <FormGroup label={t('product')}>
                <Form.Control disabled value={oneTimerOrder.product.title} type='text' />
              </FormGroup>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <FormGroup label={t('updated')}>
                <Form.Control disabled value={oneTimerOrder.updated ?? undefined} type='text' />
              </FormGroup>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <FormGroup label={t('created')}>
                <Form.Control disabled value={oneTimerOrder.created} type='text' />
              </FormGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <h5 className='card-header-title'>{t('one_timer_order_details')}</h5>
        </Card.Header>
        <Card.Body>
          <Row className='mb-3'>
            <Col>
              <FormGroup label={t('type')}>
                <Form.Control disabled value={oneTimerOrder.type.title} type='text' />
              </FormGroup>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <FormGroup label={t('price_per_outfit')}>
                <InputGroup>
                  <Form.Control
                    value={oneTimerOrder.pricePerOutfit.toFixed(2)}
                    disabled
                    type='number'
                  />
                  <CurrencySelect isReadOnly value={oneTimerOrder.pricePerOutfitCurrency} />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <FormGroup label={t('comment')}>
                <Form.Control disabled value={oneTimerOrder.comment} type='text' />
              </FormGroup>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <FormGroup label={t('seasons')}>
                <Form.Control
                  disabled
                  value={oneTimerOrder.seasons.map((season) => season.title).join(', ')}
                  type='text'
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup label={t('occasion')}>
                <Form.Control disabled value={oneTimerOrder.occasion.title} type='text' />
              </FormGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default MetaForm;
