import { FC } from 'react';
import { CellProps } from 'react-table';
import { Subscription } from '../types';
import { SubscriptionDuration } from 'api/clients/subscriptions/types';
import { useSubscriptionsTranslations } from '../hooks';

const TypeCell: FC<CellProps<Subscription, SubscriptionDuration>> = (props) => {
  const { value: duration } = props;
  const { t } = useSubscriptionsTranslations();
  return <>{t(duration)}</>;
};

export default TypeCell;
