import { Control, FieldArrayWithId } from 'react-hook-form';
import { WeeklyPlan, WeeklyPlanFormData } from '../types';
import React, { FC } from 'react';
import DetailsForm from './DetailsForm';
import { WeeklyOrder } from 'features/orders/types';
import MediaForm from './MediaForm';

interface Props {
  index: number;
  field: FieldArrayWithId<WeeklyPlanFormData, 'outfits'>;
  weeklyOrder: WeeklyOrder;
  control: Control<WeeklyPlanFormData>;
  weeklyPlan?: WeeklyPlan;
}

const OutfitForm: FC<Props> = (props) => {
  const { control, index, weeklyOrder, field, weeklyPlan } = props;

  return (
    <>
      <DetailsForm
        isDisabled={!!weeklyPlan}
        field={field}
        index={index}
        weeklyOrder={weeklyOrder}
        className='mb-3'
        control={control}
      />
      <MediaForm weeklyPlan={weeklyPlan} field={field} index={index} control={control} />
    </>
  );
};

export default OutfitForm;
