import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { RevisionFormData } from '../types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useRevisionsTranslations } from '../hooks';
import { FormGroup } from 'components';

interface Props {
  className?: string;
  control: Control<RevisionFormData>;
  isDisabled: boolean;
}

const DetailsForm: FC<Props> = (props) => {
  const { control, className, isDisabled } = props;
  const { t } = useRevisionsTranslations();

  return (
    <Card className={className}>
      <Card.Header>
        <h5 className='card-header-title'>{t('revision_details')}</h5>
      </Card.Header>
      <Card.Body>
        <Row className='mb-4'>
          <Col>
            <Controller
              defaultValue=''
              control={control}
              name='title'
              render={({ field, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('title')}>
                  <Form.Control disabled={isDisabled} isInvalid={!!error} {...field} type='text' />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Controller
              defaultValue=''
              control={control}
              name='description'
              render={({ field, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('description')}>
                  <Form.Control
                    disabled={isDisabled}
                    rows={4}
                    isInvalid={!!error}
                    {...field}
                    as='textarea'
                    type='text'
                  />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DetailsForm;
