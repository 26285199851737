import { Color, SaveColorRequest } from './types';
import httpClient from '../../httpClient';
import { DataResponse, ListResponse } from '../../types';

const baseUrl = '/admin/colors';

export const getColors = () => {
  return httpClient.get<void, ListResponse<Color>>(baseUrl);
};

export const getColor = (id: number) => {
  return httpClient.get<void, DataResponse<Color>>(`${baseUrl}/${id}`);
};

export const addColor = async (request: SaveColorRequest) => {
  return httpClient.post<SaveColorRequest, DataResponse<Color>>(baseUrl, request);
};

export const editColor = async (id: number, request: SaveColorRequest) => {
  return httpClient.put<SaveColorRequest, DataResponse<Color>>(`${baseUrl}/${id}`, request);
};

export const deleteColor = (id: number) => {
  return httpClient.delete(`${baseUrl}/${id}`);
};
