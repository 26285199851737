import { Control, Controller, FieldArrayWithId } from 'react-hook-form';
import { WeeklyPlan, WeeklyPlanFormData } from '../types';
import React, { FC, useMemo } from 'react';
import { useWeeklyPlansTranslations } from '../hooks';
import { Card } from 'react-bootstrap';
import { FileCard, PhotoControl } from 'components';
import { getAbsoluteFilePath } from 'features/outfits/utils';
import { OutfitPhoto } from 'features/outfits/types';

interface Props {
  className?: string;
  control: Control<WeeklyPlanFormData>;
  field: FieldArrayWithId<WeeklyPlanFormData, 'outfits'>;
  index: number;
  weeklyPlan?: WeeklyPlan;
}

const MediaForm: FC<Props> = (props) => {
  const { control, className, index, field, weeklyPlan } = props;
  const { t } = useWeeklyPlansTranslations();

  const photo = useMemo<OutfitPhoto | null>(() => {
    if (!weeklyPlan) return null;
    return weeklyPlan.outfits[index].photo;
  }, [weeklyPlan, index]);

  return (
    <Card className={className}>
      <Card.Header>
        <h5 className='card-header-title'>{t('media')}</h5>
      </Card.Header>
      <Card.Body>
        {!!photo && (
          <FileCard
            className='mb-2'
            name={photo.name}
            src={getAbsoluteFilePath(photo.name)}
            size={photo.size}
          />
        )}
        <Controller
          defaultValue={field.photo}
          control={control}
          name={`outfits.${index}.photo`}
          render={({ field: { onChange, value } }) => (
            <PhotoControl isDisabled={!!weeklyPlan} photo={value} onPhotoChange={onChange} />
          )}
        />
      </Card.Body>
    </Card>
  );
};

export default MediaForm;
