import React, { FC, useMemo } from 'react';
import { User } from '../types';
import { Button, Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { ItemsTable } from 'features/items/hoc';
import { ItemsFilter } from 'api/clients/items/types';
import { useUserEditRoles, useUsersTranslations } from '../hooks';
import { OrdersTable } from 'features/orders/hoc';
import { OrdersFilter } from 'api/clients/orders/types';
import { useNavigate } from 'react-router-dom';
import { ShoppingItemsFilter } from 'api/clients/shopping-items/types';
import { ShoppingItemsTable } from 'features/shopping-items/hoc';
import { OutfitsTable } from 'features/outfits/hoc';
import { OutfitsFilter } from 'api/clients/outfits/types';
import { Profile } from '../components';

interface Props {
  user: User;
}

const UserDetails: FC<Props> = (props) => {
  const { user } = props;

  const navigate = useNavigate();
  const { t } = useUsersTranslations();
  const { isLoading, addAdminRole, removeAdminRole } = useUserEditRoles();

  const itemsFilter = useMemo<ItemsFilter>(() => ({ userId: user.id }), [user.id]);
  const outfitsFilter = useMemo<OutfitsFilter>(() => ({ userId: user.id }), [user.id]);
  const ordersFilter = useMemo<OrdersFilter>(() => ({ userId: user.id }), [user.id]);
  const shoppingItemsFilter = useMemo<ShoppingItemsFilter>(() => ({ userId: user.id }), [user.id]);

  return (
    <div>
      <div className='page-header'>
        <Row className='align-items-center'>
          <Col>
            <h2 className='page-header-title'>{user.email}</h2>
          </Col>
          <Col lg='auto'>
            <Button
              disabled={isLoading}
              size='sm'
              className='me-2'
              onClick={() => (user.isAdmin ? removeAdminRole(user) : addAdminRole(user))}
              variant={user.isAdmin ? 'danger' : 'primary'}
            >
              {user.isAdmin ? t('remove_admin_role') : t('add_admin_role')}
            </Button>
            <Button size='sm' onClick={() => navigate('/users')} variant='white'>
              {t('back_to_users_list')}
            </Button>
          </Col>
        </Row>
      </div>
      <Profile user={user} />
      <hr className='my-5' />
      <Tabs defaultActiveKey='items' className='mb-3'>
        <Tab eventKey='items' title={t('items')}>
          <Card className='mb-4'>
            <ItemsTable filter={itemsFilter} />
          </Card>
        </Tab>
        <Tab eventKey='outfits' title={t('outfits')}>
          <Card className='mb-4'>
            <OutfitsTable filter={outfitsFilter} />
          </Card>
        </Tab>
        <Tab eventKey='shopping-items' title={t('shopping_items')}>
          <Card className='mb-4'>
            <ShoppingItemsTable filter={shoppingItemsFilter} />
          </Card>
        </Tab>
        <Tab eventKey='orders' title={t('orders')}>
          <Card>
            <OrdersTable filter={ordersFilter} />
          </Card>
        </Tab>
      </Tabs>
    </div>
  );
};

export default UserDetails;
