import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { useTestimonial } from 'features/testimonials/hooks';
import { TestimonialForm } from 'features/testimonials/hoc';
import { useParams } from 'react-router-dom';

const TestimonialEditPage: FC = () => {
  const params = useParams();
  const id = Number(params.id);
  const { testimonial, isLoading } = useTestimonial(id);

  return (
    <Container>
      {!!testimonial && !isLoading && <TestimonialForm testimonial={testimonial} />}
    </Container>
  );
};

export default TestimonialEditPage;
