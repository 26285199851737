import React, { FC } from 'react';
import { CellProps } from 'react-table';
import { ShoppingItem, ShoppingItemPhoto } from '../types';
import { getAbsoluteFilePath } from '../utils';
import { NetworkImage } from 'components';

const PhotoCell: FC<CellProps<ShoppingItem, ShoppingItemPhoto>> = (props) => {
  const { value: photo } = props;

  return (
    <div className='avatar'>
      <NetworkImage className='avatar-img' src={getAbsoluteFilePath(photo.name)} alt={photo.name} />
    </div>
  );
};

export default PhotoCell;
