import { useAppSelector } from 'core/redux-store';
import { useQuery } from 'react-query';
import { FaqsClient } from 'api/clients';
import { mapFaq } from '../map';
import { selectFaqsLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (lastUpdated: number) => {
  return ['faqs', lastUpdated];
};

const useFaqs = () => {
  const lastUpdated = useAppSelector(selectFaqsLastUpdated);
  const { isLoading, data } = useQuery(getQueryKey(lastUpdated), FaqsClient.getFaqs);
  return { isLoading, faqs: data?.data.map(mapFaq) ?? [], faqsCount: data?.total ?? 0 };
};

export default useFaqs;
