import { Control, Controller } from 'react-hook-form';
import { Testimonial, TestimonialFormData } from '../types';
import React, { FC } from 'react';
import { useTestimonialsTranslations } from '../hooks';
import { Card } from 'react-bootstrap';
import { FileCard, PhotoControl } from 'components';
import { getAbsoluteFilePath } from '../utils';

interface Props {
  className?: string;
  testimonial?: Testimonial;
  control: Control<TestimonialFormData>;
}

const MediaForm: FC<Props> = (props) => {
  const { control, className, testimonial } = props;
  const { t } = useTestimonialsTranslations();

  return (
    <Card className={className}>
      <Card.Header>
        <h5 className='card-header-title'>{t('media')}</h5>
      </Card.Header>
      <Card.Body>
        {!!testimonial && (
          <FileCard
            className='mb-2'
            name={testimonial.photo.name}
            src={getAbsoluteFilePath(testimonial.photo.name)}
            size={testimonial.photo.size}
          />
        )}
        <Controller
          defaultValue={null}
          control={control}
          name='photo'
          render={({ field: { onChange, value } }) => (
            <PhotoControl photo={value} onPhotoChange={onChange} />
          )}
        />
      </Card.Body>
    </Card>
  );
};

export default MediaForm;
