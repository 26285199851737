import { DataResponse, ListRequest, ListResponse } from '../../types';
import { Item, ItemsFilter } from './types';
import httpClient from '../../httpClient';

const baseUrl = '/admin/items';

export const getItems = (request: ListRequest<ItemsFilter>) => {
  const params = { ...request.filter };
  return httpClient.get<void, ListResponse<Item>>(baseUrl, { params });
};

export const getItem = (id: number) => {
  return httpClient.get<void, DataResponse<Item>>(`${baseUrl}/${id}`);
};
