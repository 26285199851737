import { useQuery } from 'react-query';
import { RevisionsClient } from 'api/clients';
import { mapRevision } from '../map';
import { useAppSelector } from 'core/redux-store';
import { selectOrdersLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (number: string, lastUpdated: number) => {
  return ['revision', number, lastUpdated];
};

const useRevision = (number: string) => {
  const lastUpdated = useAppSelector(selectOrdersLastUpdated);
  const getRevisionFn = () => RevisionsClient.getRevisionByOrderNumber(number);
  const { isLoading, data } = useQuery(getQueryKey(number, lastUpdated), getRevisionFn);
  return { isLoading, revision: data ? mapRevision(data.data) : null };
};

export default useRevision;
