import { RootState } from '../index';

export const selectItemsLastUpdated = (state: RootState): number => {
  return state.global.itemsLastUpdated;
};

export const selectUsersLastUpdated = (state: RootState): number => {
  return state.global.usersLastUpdated;
};

export const selectTestimonialsLastUpdated = (state: RootState): number => {
  return state.global.testimonialsLastUpdated;
};

export const selectShoppingItemsLastUpdated = (state: RootState): number => {
  return state.global.shoppingItemsLastUpdated;
};

export const selectProductsLastUpdated = (state: RootState): number => {
  return state.global.productsLastUpdated;
};

export const selectOutfitsLastUpdated = (state: RootState): number => {
  return state.global.outfitsLastUpdated;
};

export const selectOrdersLastUpdated = (state: RootState): number => {
  return state.global.ordersLastUpdated;
};

export const selectFaqsLastUpdated = (state: RootState): number => {
  return state.global.faqsLastUpdated;
};

export const selectColorsLastUpdated = (state: RootState): number => {
  return state.global.colorsLastUpdated;
};

export const selectRevisionPlansLastUpdated = (state: RootState): number => {
  return state.global.revisionPlansUpdated;
};

export const selectSubscriptionsLastUpdated = (state: RootState): number => {
  return state.global.subscriptionsUpdated;
};
