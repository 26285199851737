import { useQuery } from 'react-query';
import { OneTimersClient } from 'api/clients';
import { mapOneTimer } from '../map';
import { useAppSelector } from 'core/redux-store';
import { selectOrdersLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (number: string, lastUpdated: number) => {
  return ['one-timer', number, lastUpdated];
};

const useOneTimer = (number: string) => {
  const lastUpdated = useAppSelector(selectOrdersLastUpdated);
  const getOneTimerFn = () => OneTimersClient.getOneTimerByOrderNumber(number);
  const { isLoading, data } = useQuery(getQueryKey(number, lastUpdated), getOneTimerFn);
  return { isLoading, oneTimer: data ? mapOneTimer(data.data) : null };
};

export default useOneTimer;
