import { SaveShoppingItemRequest, ShoppingItem, ShoppingItemsFilter } from './types';
import httpClient from '../../httpClient';
import { DataResponse, ListRequest, ListResponse } from '../../types';

const baseUrl = '/admin/shopping-items';

export const getShoppingItems = (request: ListRequest<ShoppingItemsFilter>) => {
  const params = { ...request.filter };
  return httpClient.get<void, ListResponse<ShoppingItem>>(baseUrl, { params });
};

export const getShoppingItem = (id: number) => {
  return httpClient.get<void, DataResponse<ShoppingItem>>(`${baseUrl}/${id}`);
};

export const addShoppingItem = async (request: SaveShoppingItemRequest) => {
  return httpClient.post<SaveShoppingItemRequest, DataResponse<ShoppingItem>>(baseUrl, request);
};

export const editShoppingItem = async (id: number, request: SaveShoppingItemRequest) => {
  return httpClient.put<SaveShoppingItemRequest, DataResponse<ShoppingItem>>(
    `${baseUrl}/${id}`,
    request
  );
};

export const deleteShoppingItem = (id: number) => {
  return httpClient.delete(`${baseUrl}/${id}`);
};
