import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { useSubscription } from 'features/subscriptions/hooks';
import { SubscriptionForm } from 'features/subscriptions/hoc';
import { useParams } from 'react-router-dom';

const SubscriptionEditPage: FC = () => {
  const params = useParams();
  const id = Number(params.id);
  const { subscription, isLoading } = useSubscription(id);

  return (
    <Container>
      {!!subscription && !isLoading && <SubscriptionForm subscription={subscription} />}
    </Container>
  );
};

export default SubscriptionEditPage;
