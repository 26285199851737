import React, { FC } from 'react';
import { Card, Container } from 'react-bootstrap';
import { useOrdersTranslations } from 'features/orders/hooks';
import { OrdersTable } from 'features/orders/hoc';

const OrdersPage: FC = () => {
  const { t } = useOrdersTranslations();

  return (
    <Container>
      <div className='page-header d-flex align-items-center justify-content-between'>
        <h2 className='page-header-title'>{t('orders')}</h2>
      </div>
      <Card>
        <OrdersTable />
      </Card>
    </Container>
  );
};

export default OrdersPage;
