import {
  RevisionPlan as ApiRevisionPlan,
  SaveRevisionPlanRequest,
} from 'api/clients/revision-plans/types';
import { RevisionPlan, RevisionPlanFormData } from './types';
import moment from 'moment';

export const mapRevisionPlan = (revisionPlan: ApiRevisionPlan): RevisionPlan => ({
  ...revisionPlan,
  updated: revisionPlan.updated ? moment(revisionPlan.updated).format('YYYY-MM-DD HH:mm:ss') : null,
  created: moment(revisionPlan.created).format('YYYY-MM-DD HH:mm:ss'),
});

export const mapRevisionPlanFormData = (revisionPlan: RevisionPlan): RevisionPlanFormData => ({
  title: revisionPlan.title,
  enabled: revisionPlan.enabled,
  currency: revisionPlan.currency,
  price: revisionPlan.price,
});

export const mapSaveRevisionPlanRequest = (
  formData: RevisionPlanFormData
): SaveRevisionPlanRequest => formData;
