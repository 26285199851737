import React, { FC, useMemo } from 'react';
import { useItems, useItemsTranslations } from '../hooks';
import { DataTable, UpdatedCell } from 'components';
import { CellProps, Column } from 'react-table';
import { Item } from '../types';
import { ColorsCell, PhotoCell, StatusCell } from '../components';
import { ItemsFilter } from 'api/clients/items/types';
import { Occasion, Season } from 'api/clients/classificators/types';

interface Props {
  filter: ItemsFilter;
}

const ItemsTable: FC<Props> = (props) => {
  const { filter } = props;
  const { t } = useItemsTranslations();
  const { items } = useItems(filter);

  const columns = useMemo<Column<Item>[]>(
    () => [
      {
        Header: t('id'),
        accessor: 'id',
      },
      {
        Header: t('photo'),
        Cell: PhotoCell,
        disableSortBy: true,
        accessor: 'photo',
      },
      {
        Header: t('category'),
        accessor: 'category',
        Cell: (cell: CellProps<any>) => cell.row.original.category?.title ?? t('none'),
      },
      {
        Header: t('colors'),
        accessor: 'colors',
        disableSortBy: true,
        Cell: ColorsCell,
      },
      {
        Header: t('status'),
        accessor: 'status',
        Cell: StatusCell,
      },
      {
        Header: t('seasons'),
        accessor: 'seasons',
        disableSortBy: true,
        Cell: (cell: CellProps<any>) =>
          cell.row.original.seasons.length > 0
            ? cell.row.original.seasons.map((season: Season) => season.title).join(', ')
            : t('none'),
      },
      {
        Header: t('occasions'),
        accessor: 'occasions',
        disableSortBy: true,
        Cell: (cell: CellProps<any>) =>
          cell.row.original.occasions.length > 0
            ? cell.row.original.occasions.map((occasion: Occasion) => occasion.title).join(', ')
            : t('none'),
      },
      {
        Header: t('updated'),
        accessor: 'updated',
        Cell: UpdatedCell,
      },
      {
        Header: t('created'),
        accessor: 'created',
      },
    ],
    [t]
  );

  return <DataTable data={items} columns={columns} />;
};

export default ItemsTable;
