import httpClient from '../../httpClient';
import { DataResponse } from '../../types';
import { OneTimer, SaveOneTimerRequest } from './types';

const baseUrl = '/admin/one-timers';

export const getOneTimerByOrderNumber = (number: string) => {
  return httpClient.get<void, DataResponse<OneTimer>>(`${baseUrl}/${number}`);
};

export const addOneTimer = async (request: SaveOneTimerRequest) => {
  return httpClient.post<SaveOneTimerRequest, DataResponse<OneTimer>>(baseUrl, request);
};
