import { FC, useEffect, useState } from 'react';
import { diffInSeconds, formatTime } from 'utils/date';
import classNames from 'classnames';

interface Props {
  className?: string;
  endDate: string;
}

const Timer: FC<Props> = (props) => {
  const { endDate } = props;
  const [secondsLeft, setSecondsLeft] = useState<number>(diffInSeconds(endDate));

  useEffect(() => {
    const interval = setInterval(() => setSecondsLeft(diffInSeconds(endDate)), 1000);
    return () => clearInterval(interval);
  }, [endDate]);

  return (
    <span className={classNames({ 'text-danger': secondsLeft < 0 })}>
      {formatTime(secondsLeft)}
    </span>
  );
};

export default Timer;
