import React, { FC, useCallback, useMemo } from 'react';
import { Control, Controller, FieldArrayWithId } from 'react-hook-form';
import { WeeklyPlanFormData } from '../types';
import { Card, Col, Row } from 'react-bootstrap';
import { useWeeklyPlansTranslations } from '../hooks';
import { FormGroup, ImageOption } from 'components';
import Select from 'react-select';
import { useItems } from 'features/items/hooks';
import { WeeklyOrder } from 'features/orders/types';
import { useShoppingItems } from 'features/shopping-items/hooks';
import { getAbsoluteFilePath as getAbsoluteItemFilePath } from 'features/items/utils';
import { getAbsoluteFilePath as getAbsoluteShoppingItemFilePath } from 'features/shopping-items/utils';
import { useOccasions } from 'features/classificators/hooks';

interface Props {
  className?: string;
  field: FieldArrayWithId<WeeklyPlanFormData, 'outfits'>;
  control: Control<WeeklyPlanFormData>;
  weeklyOrder: WeeklyOrder;
  index: number;
  isDisabled: boolean;
}

const DetailsForm: FC<Props> = (props) => {
  const { field, className, weeklyOrder, index, control, isDisabled } = props;
  const { t } = useWeeklyPlansTranslations();

  const { occasions, isLoading: isOccasionsLoading } = useOccasions();
  const { items, isLoading: isItemsLoading } = useItems({ userId: weeklyOrder.user.id });
  const { shoppingItems, isLoading: isShoppingItemsLoading } = useShoppingItems();

  const occasionsOptions = useMemo(
    () => occasions.map((occasion) => ({ value: occasion.id, label: occasion.title })),
    [occasions]
  );

  const findOccasionOption = useCallback(
    (id: number | null) => occasionsOptions.find((occasion) => occasion.value === id),
    [occasionsOptions]
  );

  const itemsOptions = useMemo(
    () =>
      items.map((item) => ({
        value: item.id,
        label: item.id.toString(),
        src: getAbsoluteItemFilePath(item.photo.name),
      })),
    [items]
  );

  const findItemsOptions = useCallback(
    (ids: number[]) => itemsOptions.filter((option) => ids.includes(option.value)),
    [itemsOptions]
  );

  const shoppingItemsOptions = useMemo(
    () =>
      shoppingItems.map((shoppingItem) => ({
        value: shoppingItem.id,
        label: shoppingItem.id.toString(),
        src: getAbsoluteShoppingItemFilePath(shoppingItem.photo.name),
      })),
    [shoppingItems]
  );

  const findShoppingItemsOptions = useCallback(
    (ids: number[]) => shoppingItemsOptions.filter((option) => ids.includes(option.value)),
    [shoppingItemsOptions]
  );

  return (
    <Card className={className}>
      <Card.Header>
        <h5 className='card-header-title'>{t('outfit_details')}</h5>
      </Card.Header>
      <Card.Body>
        <Row className='mb-4'>
          <Col>
            <Controller
              defaultValue={field.occasionId}
              control={control}
              name={`outfits.${index}.occasionId`}
              render={({ field: { value, onChange, ...rest }, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('occasion')}>
                  <Select
                    isDisabled={isDisabled}
                    isLoading={isOccasionsLoading}
                    isClearable
                    placeholder={t('select_occasion')}
                    options={occasionsOptions}
                    value={findOccasionOption(value)}
                    onChange={(option) => onChange(option?.value ?? null)}
                    {...rest}
                  />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col>
            <Controller
              defaultValue={field.itemsIds}
              control={control}
              name={`outfits.${index}.itemsIds`}
              render={({ field: { value, onChange, ...rest }, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('items')}>
                  <Select
                    isDisabled={isDisabled}
                    isLoading={isItemsLoading}
                    isClearable
                    isMulti
                    formatOptionLabel={(option) => <ImageOption {...option} />}
                    placeholder={t('select_items')}
                    options={itemsOptions}
                    value={findItemsOptions(value)}
                    onChange={(options) => onChange(options.map((option) => option.value))}
                    {...rest}
                  />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Controller
              defaultValue={field.shoppingItemsIds}
              control={control}
              name={`outfits.${index}.shoppingItemsIds`}
              render={({ field: { value, onChange, ...rest }, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('shopping_items')}>
                  <Select
                    isDisabled={isDisabled}
                    isMulti
                    isLoading={isShoppingItemsLoading}
                    isClearable
                    formatOptionLabel={(option) => <ImageOption {...option} />}
                    placeholder={t('select_shopping_items')}
                    options={shoppingItemsOptions}
                    value={findShoppingItemsOptions(value)}
                    onChange={(options) => onChange(options.map((option) => option.value))}
                    {...rest}
                  />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DetailsForm;
