import React, { FC, useCallback } from 'react';
import { useUserDelete, useUsersTranslations } from '../hooks';
import { useAppSelector } from 'core/redux-store';
import { selectUserToDelete } from '../selectors';
import { resetUserToDelete } from '../slice';
import { useDispatch } from 'react-redux';
import { ConfirmationModal } from 'components';

const UserDeleteConfirmation: FC = () => {
  const { t } = useUsersTranslations();
  const dispatch = useDispatch();

  const { deleteUser, isLoading } = useUserDelete();
  const userToDelete = useAppSelector(selectUserToDelete);

  const handleOnConfirmationModalClose = useCallback(() => {
    dispatch(resetUserToDelete());
  }, [dispatch]);

  return (
    <ConfirmationModal
      isActionsDisabled={isLoading}
      isVisible={!!userToDelete}
      content={userToDelete?.email}
      onClose={handleOnConfirmationModalClose}
      title={t('are_you_sure_want_to_delete_this_user')}
      confirmLabel={t('delete')}
      onConfirm={deleteUser}
    />
  );
};

export default UserDeleteConfirmation;
