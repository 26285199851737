import React, { FC, ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';

interface Props {
  label: string;
  error?: string;
  help?: string;
  className?: string;
  row?: boolean;
  children: ReactNode;
}

const FormGroup: FC<Props> = (props) => {
  const { label, error, children, className, help, row = false } = props;

  return (
    <Form.Group className={className}>
      <div className={classNames('d-flex', { 'flex-column': !row })}>
        <Form.Label className={classNames('flex-grow-1', { 'mb-0': row })}>{label}</Form.Label>
        {children}
      </div>
      {!!help && <Form.Text muted>{help}</Form.Text>}
      <Form.Text className='invalid-feedback'>{error}</Form.Text>
    </Form.Group>
  );
};

export default FormGroup;
