import { useAppSelector } from 'core/redux-store';
import { useQuery } from 'react-query';
import { ItemsClient } from 'api/clients';
import { mapItem } from '../map';
import { ItemsFilter } from 'api/clients/items/types';
import { selectItemsLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (filter: ItemsFilter, lastUpdated: number) => {
  return ['items', filter, lastUpdated];
};

const useItems = (filter: ItemsFilter) => {
  const lastUpdated = useAppSelector(selectItemsLastUpdated);
  const getItemsFn = () => ItemsClient.getItems({ filter });
  const { isLoading, data } = useQuery(getQueryKey(filter, lastUpdated), getItemsFn);
  return { isLoading, items: data?.data.map(mapItem) ?? [], itemsCount: data?.total ?? 0 };
};

export default useItems;
