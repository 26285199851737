import { Color as ApiColor, SaveColorRequest } from 'api/clients/colors/types';
import { Color, ColorFormData } from './types';
import moment from 'moment';

export const mapColor = (color: ApiColor): Color => ({
  ...color,
  updated: color.updated ? moment(color.updated).format('YYYY-MM-DD HH:mm:ss') : null,
  created: moment(color.created).format('YYYY-MM-DD HH:mm:ss'),
});

export const mapColorFormData = (color: Color): ColorFormData => ({
  title: color.title,
  enabled: color.enabled,
  code: color.code,
  matchingColorsIds: color.matchingColors.map((matchingColor) => matchingColor.id),
});

export const mapSaveColorRequest = (formData: ColorFormData): SaveColorRequest => formData;
