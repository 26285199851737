import { FC } from 'react';
import { CellProps } from 'react-table';
import { Color } from '../types';

const CodeCell: FC<CellProps<Color, string>> = (props) => {
  const { value: code, row } = props;
  const color = row.original;

  return (
    <div className='d-flex flex-row align-items-center'>
      <div className='dot me-2' style={{ backgroundColor: code }}></div>
      <span>{color.code}</span>
    </div>
  );
};

export default CodeCell;
