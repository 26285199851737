import React, { FC } from 'react';
import { CellProps } from 'react-table';
import { Outfit, OutfitPhoto } from '../types';
import { getAbsoluteFilePath } from '../utils';
import { NetworkImage } from 'components';

const PhotoCell: FC<CellProps<Outfit, OutfitPhoto | null>> = (props) => {
  const { value: photo } = props;

  return (
    <div className='avatar'>
      {!!photo && (
        <NetworkImage
          className='avatar-img'
          src={getAbsoluteFilePath(photo.name)}
          alt={photo.name}
        />
      )}
    </div>
  );
};

export default PhotoCell;
