import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Color } from './types';
import { logout } from 'features/auth/slice';

interface State {
  colorToDelete: Color | null;
}

const initialState: State = {
  colorToDelete: null,
};

const colorsSlice = createSlice({
  name: 'colors',
  initialState,
  reducers: {
    setColorToDelete: (state, action: PayloadAction<{ color: Color }>) => {
      state.colorToDelete = action.payload.color;
    },
    resetColorToDelete: (state) => {
      state.colorToDelete = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const { setColorToDelete, resetColorToDelete } = colorsSlice.actions;
export const { reducer } = colorsSlice;
