import React, { FC, useMemo } from 'react';
import LegendIndicator, { LegendIndicatorVariant } from 'components/LegendIndicator';
import { CellProps } from 'react-table';
import { useItemsTranslations } from '../hooks';
import { ItemStatus } from 'api/clients/items/types';

interface MemoValue {
  variant: LegendIndicatorVariant;
  label: string;
}

const variantMap: Record<ItemStatus, LegendIndicatorVariant> = {
  active: 'secondary',
  rejected: 'danger',
  approved: 'success',
};

const StatusCell: FC<CellProps<any, ItemStatus>> = (props) => {
  const { value: status } = props;
  const { t } = useItemsTranslations();

  const variant = useMemo(() => variantMap[status], [status]);

  const memoizedValue = useMemo<MemoValue>(
    () => ({
      variant: variant,
      label: t(status),
    }),
    [status, t, variant]
  );

  return <LegendIndicator variant={memoizedValue.variant} label={memoizedValue.label} />;
};

export default StatusCell;
