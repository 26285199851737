import { useQuery } from 'react-query';
import { TestimonialsClient } from 'api/clients';
import { mapTestimonial } from '../map';
import { useAppSelector } from 'core/redux-store';
import { selectTestimonialsLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (id: number, lastUpdated: number) => {
  return ['testimonial', id, lastUpdated];
};

const useTestimonial = (id: number) => {
  const lastUpdated = useAppSelector(selectTestimonialsLastUpdated);
  const getTestimonialFn = () => TestimonialsClient.getTestimonial(id);
  const { isLoading, data } = useQuery(getQueryKey(id, lastUpdated), getTestimonialFn);
  return { isLoading, testimonial: data ? mapTestimonial(data.data) : null };
};

export default useTestimonial;
