import { Faq, SaveFaqRequest } from './types';
import httpClient from '../../httpClient';
import { DataResponse, ListResponse } from '../../types';

const baseUrl = '/admin/faqs';

export const getFaqs = () => {
  return httpClient.get<void, ListResponse<Faq>>(baseUrl);
};

export const getFaq = (id: number) => {
  return httpClient.get<void, DataResponse<Faq>>(`${baseUrl}/${id}`);
};

export const addFaq = async (request: SaveFaqRequest) => {
  return httpClient.post<SaveFaqRequest, DataResponse<Faq>>(baseUrl, request);
};

export const editFaq = async (id: number, request: SaveFaqRequest) => {
  return httpClient.put<SaveFaqRequest, DataResponse<Faq>>(`${baseUrl}/${id}`, request);
};

export const deleteFaq = (id: number) => {
  return httpClient.delete(`${baseUrl}/${id}`);
};
