import { FC } from 'react';
import { CellProps } from 'react-table';
import { useTranslation } from 'react-i18next';

const UpdatedCell: FC<CellProps<any, string | null>> = (props) => {
  const { value: updated } = props;
  const { t } = useTranslation('common');

  if (!updated) return t('not_updated');
  return <>{updated}</>;
};

export default UpdatedCell;
