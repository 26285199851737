import React, { FC } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import currencies, { CurrencyCode } from 'constants/currencies';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface Props {
  value: CurrencyCode | null;
  onChange?: (value: CurrencyCode) => void;
  className?: string;
  isReadOnly?: boolean;
}

const CurrencySelect: FC<Props> = (props) => {
  const { value, onChange, className, isReadOnly = false } = props;
  const { t } = useTranslation('common');

  return (
    <DropdownButton
      disabled={isReadOnly}
      className={classNames('no-arrow', className)}
      variant='white'
      title={value ? currencies[value] : t('none')}
    >
      {Object.entries(currencies).map(([code, label]) => (
        <Dropdown.Item
          active={code === value}
          onClick={() => onChange?.(code as CurrencyCode)}
          as='div'
          key={code}
        >
          {label}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default CurrencySelect;
