import { useQuery } from 'react-query';
import { ProductsClient } from 'api/clients';
import { mapProduct } from '../map';
import { useAppSelector } from 'core/redux-store';
import { selectProductsLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (id: number, lastUpdated: number) => {
  return ['product', id, lastUpdated];
};

const useProduct = (id: number) => {
  const lastUpdated = useAppSelector(selectProductsLastUpdated);
  const getProductFn = () => ProductsClient.getProduct(id);
  const { isLoading, data } = useQuery(getQueryKey(id, lastUpdated), getProductFn);
  return { isLoading, product: data ? mapProduct(data.data) : null };
};

export default useProduct;
