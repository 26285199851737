import React, { FC } from 'react';
import { SubscriptionDeleteConfirmation, SubscriptionsTable } from 'features/subscriptions/hoc';
import { useSubscriptionsTranslations } from 'features/subscriptions/hooks';
import { Button, Card, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const SubscriptionsPage: FC = () => {
  const { t } = useSubscriptionsTranslations();
  const navigate = useNavigate();

  return (
    <Container>
      <div className='page-header d-flex align-items-center justify-content-between'>
        <h2 className='page-header-title'>{t('subscriptions')}</h2>
        <Button onClick={() => navigate('add')} size='sm' variant='primary'>
          {t('add_subscription')}
        </Button>
      </div>
      <Card>
        <SubscriptionsTable />
        <SubscriptionDeleteConfirmation />
      </Card>
    </Container>
  );
};

export default SubscriptionsPage;
