import { useAppDispatch, useAppSelector } from 'core/redux-store';
import { useMutation } from 'react-query';
import { FaqsClient } from 'api/clients';
import { resetFaqToDelete } from '../slice';
import { showSuccessToast } from 'core/toast';
import useFaqsTranslations from './useFaqsTranslations';
import { useNavigate } from 'react-router-dom';
import { selectFaqToDelete } from '../selectors';
import { updateFaqs } from 'core/redux-store/global/slice';

const useFaqDelete = () => {
  const { t } = useFaqsTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const faqToDelete = useAppSelector(selectFaqToDelete);

  const { mutateAsync: deleteFaq, isLoading } = useMutation(
    async () => (faqToDelete ? FaqsClient.deleteFaq(faqToDelete.id) : undefined),
    {
      onSuccess: () => {
        dispatch(updateFaqs());
        dispatch(resetFaqToDelete());
        showSuccessToast(t('faq_is_successfully_deleted'));
        navigate('/faqs');
      },
    }
  );

  return { isLoading, deleteFaq };
};

export default useFaqDelete;
