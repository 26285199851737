import {
  WeeklyPlan as ApiWeeklyPlan,
  SaveWeeklyPlanRequest,
  OutfitPayload as ApiOutfitPayload,
} from 'api/clients/weekly-plans/types';
import { WeeklyPlan, WeeklyPlanFormData, OutfitPayload } from './types';
import moment from 'moment';
import { mapOutfit } from 'features/outfits/map';
import { Outfit } from 'features/outfits/types';

export const mapWeeklyPlan = (weeklyPlan: ApiWeeklyPlan): WeeklyPlan => ({
  ...weeklyPlan,
  outfits: weeklyPlan.outfits.map(mapOutfit),
  date: moment(weeklyPlan.date).format('YYYY-MM-DD HH:mm:ss'),
  updated: weeklyPlan.updated ? moment(weeklyPlan.updated).format('YYYY-MM-DD HH:mm:ss') : null,
  created: moment(weeklyPlan.created).format('YYYY-MM-DD HH:mm:ss'),
});

export const mapOutfitPayload = (payload: OutfitPayload): ApiOutfitPayload => ({
  ...payload,
  photo: payload.photo?.value ?? null,
});

export const mapWeeklyPlanOutfitPayload = (outfit: Outfit): OutfitPayload => ({
  itemsIds: outfit.outfitItems.map((outfitItem) => outfitItem.item.id),
  shoppingItemsIds: outfit.shoppingItems.map((shoppingItem) => shoppingItem.id),
  occasionId: outfit.occasion?.id ?? null,
  photo: null,
});

export const mapWeeklyPlanFormData = (weeklyPlan: WeeklyPlan): WeeklyPlanFormData => ({
  outfits: weeklyPlan.outfits.map(mapWeeklyPlanOutfitPayload),
});

export const mapSaveWeeklyPlanRequest = (
  formData: WeeklyPlanFormData
): Omit<SaveWeeklyPlanRequest, 'weeklyOrderNumber'> => ({
  ...formData,
  outfits: formData.outfits?.map(mapOutfitPayload) ?? [],
});
