import { Faq, FaqFormData } from '../types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { FaqsClient } from 'api/clients';
import { useEffect } from 'react';
import { mapFaqFormData, mapSaveFaqRequest } from '../map';
import { useAppDispatch } from 'core/redux-store';
import { showSuccessToast } from 'core/toast';
import useFaqsTranslations from './useFaqsTranslations';
import { useNavigate } from 'react-router-dom';
import { updateFaqs } from 'core/redux-store/global/slice';

const faqShape = yup.object().shape({
  enabled: yup.boolean().required(),
  title: yup.string().required(),
  content: yup.string().required(),
});

const getSchema = () => faqShape.required();

const useFaqForm = (faq?: Faq) => {
  const { t } = useFaqsTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { handleSubmit, control, reset } = useForm<FaqFormData>({
    resolver: yupResolver(getSchema()),
  });

  useEffect(() => {
    if (!faq) return;
    reset(mapFaqFormData(faq));
  }, [faq, reset]);

  const { mutateAsync, isLoading } = useMutation(
    async (formData: FaqFormData) => {
      const request = mapSaveFaqRequest(formData);
      if (!faq) await FaqsClient.addFaq(request);
      else await FaqsClient.editFaq(faq.id, request);
    },
    {
      onSuccess: () => {
        dispatch(updateFaqs());
        showSuccessToast(t('faq_is_successfully_saved'));
        navigate('/faqs');
      },
    }
  );

  const save = async (formData: FaqFormData) => {
    await mutateAsync(formData);
  };

  return { handleSubmit, save, control, isLoading };
};

export default useFaqForm;
