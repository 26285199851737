import React, { FC } from 'react';
import Table, { TableProps } from './Table';

type Props = Omit<TableProps, 'className'> & {
  className?: string;
};

const DataTable: FC<Props> = (props) => {
  const { className, data, columns, onRowClick, hiddenColumns, sortBy } = props;

  return (
    <div className={className}>
      <Table
        sortBy={sortBy}
        hiddenColumns={hiddenColumns}
        onRowClick={onRowClick}
        data={data}
        columns={columns}
      />
    </div>
  );
};

export default DataTable;
