import React, { FC, useMemo } from 'react';
import { Item } from 'features/items/types';
import { Card } from 'react-bootstrap';
import { useRevisionsTranslations } from '../hooks';
import ItemStatusChoice from './ItemStatusChoice';
import { Control, Controller } from 'react-hook-form';
import { RevisionFormData } from '../types';
import { ItemStatus } from '../../../api/clients/items/types';

interface Props {
  items: Item[];
  control: Control<RevisionFormData>;
  className?: string;
  isDisabled: boolean;
}

const ItemsForm: FC<Props> = (props) => {
  const { items, className, control, isDisabled } = props;
  const { t } = useRevisionsTranslations();

  const defaultValue = useMemo<Record<number, ItemStatus>>(
    () => items.reduce((obj, item) => Object.assign(obj, { [item.id]: item.status }), {}),
    [items]
  );

  return (
    <Card className={className}>
      <Card.Header>
        <h5 className='card-header-title'>{t('items')}</h5>
      </Card.Header>
      <Card.Body>
        <Controller
          defaultValue={defaultValue}
          control={control}
          name='items'
          render={({ field }) => (
            <div className='items-grid'>
              {items.map((item) => (
                <ItemStatusChoice
                  isDisabled={isDisabled}
                  key={item.id}
                  item={item}
                  value={field.value[item.id]}
                  onChange={(status) => field.onChange({ ...field.value, [item.id]: status })}
                />
              ))}
            </div>
          )}
        />
      </Card.Body>
    </Card>
  );
};

export default ItemsForm;
