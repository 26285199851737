import React, { FC } from 'react';
import { UserDeleteConfirmation, UsersTable } from 'features/users/hoc';
import { useUsersTranslations } from 'features/users/hooks';
import { Card, Container } from 'react-bootstrap';

const UsersPage: FC = () => {
  const { t } = useUsersTranslations();

  return (
    <Container>
      <div className='page-header'>
        <h2 className='page-header-title'>{t('users')}</h2>
      </div>
      <Card>
        <UsersTable />
        <UserDeleteConfirmation />
      </Card>
    </Container>
  );
};

export default UsersPage;
