import React, { FC, useMemo } from 'react';
import LegendIndicator, { LegendIndicatorVariant } from 'components/LegendIndicator';
import { CellProps } from 'react-table';
import { useOrdersTranslations } from '../hooks';
import { OrderStatus } from 'api/clients/orders/types';

interface MemoValue {
  variant: LegendIndicatorVariant;
  label: string;
}

const variantMap: Record<OrderStatus, LegendIndicatorVariant> = {
  completed: 'dark',
  preparing: 'warning',
  ongoing: 'success',
  unpaid: 'danger',
};

const StatusCell: FC<CellProps<any, OrderStatus>> = (props) => {
  const { value: status } = props;
  const { t } = useOrdersTranslations();

  const variant = useMemo(() => variantMap[status], [status]);

  const memoizedValue = useMemo<MemoValue>(
    () => ({
      variant: variant,
      label: t(status),
    }),
    [status, t, variant]
  );

  return <LegendIndicator variant={memoizedValue.variant} label={memoizedValue.label} />;
};

export default StatusCell;
