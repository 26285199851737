import httpClient from '../../httpClient';
import { ListResponse } from '../../types';
import {
  Category,
  ClothingSize,
  Occasion,
  OneTimerOrderType,
  RevisionPlan,
  Season,
  ShoeSize,
} from './types';

export const getCategories = () => {
  return httpClient.get<void, ListResponse<Category>>('/categories');
};

export const getClothingSizes = () => {
  return httpClient.get<void, ListResponse<ClothingSize>>('/clothing-sizes');
};

export const getOccasions = () => {
  return httpClient.get<void, ListResponse<Occasion>>('/occasions');
};

export const getSeasons = () => {
  return httpClient.get<void, ListResponse<Season>>('/seasons');
};

export const getShoeSizes = () => {
  return httpClient.get<void, ListResponse<ShoeSize>>('/shoe-sizes');
};

export const getRevisionPlans = () => {
  return httpClient.get<void, ListResponse<RevisionPlan>>('/revision-plans');
};

export const getOneTimerOrderTypes = () => {
  return httpClient.get<void, ListResponse<OneTimerOrderType>>('/one-timer-order-types');
};
