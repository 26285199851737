import { useQuery } from 'react-query';
import { RevisionPlansClient } from 'api/clients';
import { mapRevisionPlan } from '../map';
import { useAppSelector } from 'core/redux-store';
import { selectRevisionPlansLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (id: number, lastUpdated: number) => {
  return ['revision-plan', id, lastUpdated];
};

const useRevisionPlan = (id: number) => {
  const lastUpdated = useAppSelector(selectRevisionPlansLastUpdated);
  const getRevisionPlanFn = () => RevisionPlansClient.getRevisionPlan(id);
  const { isLoading, data } = useQuery(getQueryKey(id, lastUpdated), getRevisionPlanFn);
  return { isLoading, revisionPlan: data ? mapRevisionPlan(data.data) : null };
};

export default useRevisionPlan;
