import { Revision as ApiRevision, SaveRevisionRequest } from 'api/clients/revisions/types';
import { Revision, RevisionFormData } from './types';
import moment from 'moment';
import { ItemStatus } from '../../api/clients/items/types';

export const mapRevision = (revision: ApiRevision): Revision => ({
  ...revision,
  date: moment(revision.date).format('YYYY-MM-DD HH:mm:ss'),
  updated: revision.updated ? moment(revision.updated).format('YYYY-MM-DD HH:mm:ss') : null,
  created: moment(revision.created).format('YYYY-MM-DD HH:mm:ss'),
});

export const mapRevisionFormData = (revision: Revision): RevisionFormData => ({
  title: revision.title,
  description: revision.description,
  items: Object.fromEntries(revision.revisionItems.map((item) => [item.item.id, item.status])),
});

const getItemIdByStatus = (
  items: Record<number, ItemStatus>,
  filterStatus: ItemStatus
): number[] => {
  return (
    Object.entries(items)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, status]) => status === filterStatus)
      .map(([id]) => Number(id))
  );
};

export const mapSaveRevisionRequest = (
  formData: RevisionFormData
): Omit<SaveRevisionRequest, 'revisionOrderNumber'> => ({
  title: formData.title,
  description: formData.description,
  rejectedItemsIds: getItemIdByStatus(formData.items, 'rejected'),
  approvedItemsIds: getItemIdByStatus(formData.items, 'approved'),
});
