import { combineReducers, configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
import { reducer as authReducer } from 'features/auth/slice';
import { reducer as usersReducer } from 'features/users/slice';
import { reducer as faqsReducer } from 'features/faqs/slice';
import { reducer as colorsReducer } from 'features/colors/slice';
import { reducer as productsReducer } from 'features/products/slice';
import { reducer as testimonialsReducer } from 'features/testimonials/slice';
import { reducer as shoppingItemsReducer } from 'features/shopping-items/slice';
import { reducer as revisionPlansReducer } from 'features/revision-plans/slice';
import { reducer as subscriptionsReducer } from 'features/subscriptions/slice';
import { reducer as globalReducer } from './global/slice';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  faqs: faqsReducer,
  colors: colorsReducer,
  testimonials: testimonialsReducer,
  shoppingItems: shoppingItemsReducer,
  products: productsReducer,
  revisionPlans: revisionPlansReducer,
  global: globalReducer,
  subscriptions: subscriptionsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const ignoredActions = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER];

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (get) => get({ serializableCheck: { ignoredActions } }).concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
