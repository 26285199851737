import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RevisionPlan } from './types';
import { logout } from 'features/auth/slice';

interface State {
  revisionPlanToDelete: RevisionPlan | null;
}

const initialState: State = {
  revisionPlanToDelete: null,
};

const revisionPlansSlice = createSlice({
  name: 'revisionPlans',
  initialState,
  reducers: {
    setRevisionPlanToDelete: (state, action: PayloadAction<{ revisionPlan: RevisionPlan }>) => {
      state.revisionPlanToDelete = action.payload.revisionPlan;
    },
    resetRevisionPlanToDelete: (state) => {
      state.revisionPlanToDelete = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const { setRevisionPlanToDelete, resetRevisionPlanToDelete } = revisionPlansSlice.actions;
export const { reducer } = revisionPlansSlice;
