import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { FaqForm } from 'features/faqs/hoc';

const FaqAddPage: FC = () => {
  return (
    <Container>
      <FaqForm />
    </Container>
  );
};

export default FaqAddPage;
