import React, { FC } from 'react';
import { Column, SortingRule, usePagination, useSortBy, useTable } from 'react-table';
import classNames from 'classnames';
import Pagination from './Pagination';

export type TableRowType = any;

export interface TableProps {
  className?: string;
  data: TableRowType[];
  columns: Column<TableRowType>[];
  hiddenColumns?: string[];
  onRowClick?: (row: TableRowType) => void;
  sortBy?: SortingRule<object>[];
}

const Table: FC<TableProps> = (props) => {
  const { className, columns, data, onRowClick, hiddenColumns = [], sortBy = [] } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns, sortBy },
      manualSortBy: false,
      autoResetSortBy: false,
      disableMultiSort: true,
      disableSortRemove: true,
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className={classNames('table-responsive datatable-custom', className)}>
        <table
          className='table table-borderless table-thead-bordered table-nowrap table-align-middle card-table no-footer'
          {...getTableProps()}
        >
          <thead className='thead-light'>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className={classNames({
                      sorting: column.canSort,
                      sorting_desc: column.isSorted && !column.isSortedDesc,
                      sorting_asc: column.isSorted && column.isSortedDesc,
                    })}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr onClick={() => onRowClick?.(row.original)} {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className='card-footer'>
        <Pagination
          gotToPage={gotoPage}
          paging={{ limit: pageSize, offset: pageIndex * pageSize }}
          total={rows.length}
        />
      </div>
    </>
  );
};

export default Table;
