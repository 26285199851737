import { Outfit as ApiOutfit, OutfitItem as ApiOutfitItem } from 'api/clients/outfits/types';
import { Outfit, OutfitItem } from './types';
import moment from 'moment';
import { mapItem } from 'features/items/map';
import { mapShoppingItem } from 'features/shopping-items/map';

export const mapOutfitItem = (outfitItem: ApiOutfitItem): OutfitItem => ({
  ...outfitItem,
  item: mapItem(outfitItem.item),
  updated: outfitItem.updated ? moment(outfitItem.updated).format('YYYY-MM-DD HH:mm:ss') : null,
  created: moment(outfitItem.created).format('YYYY-MM-DD HH:mm:ss'),
});

export const mapOutfit = (outfit: ApiOutfit): Outfit => ({
  ...outfit,
  shoppingItems: outfit.shoppingItems.map(mapShoppingItem),
  outfitItems: outfit.outfitItems.map(mapOutfitItem),
  enabledFrom: outfit.enabledFrom ? moment(outfit.enabledFrom).format('YYYY-MM-DD') : null,
  updated: outfit.updated ? moment(outfit.updated).format('YYYY-MM-DD HH:mm:ss') : null,
  created: moment(outfit.created).format('YYYY-MM-DD HH:mm:ss'),
});
