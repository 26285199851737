import moment from 'moment';
import dayjs from 'dayjs';

export const formatDate = (date: string, format = 'YYYY-MM-DD'): string => {
  return moment(date).format(format);
};

export const formatTime = (remainingTime: number): string => {
  const hours = Math.floor(remainingTime / 3600).toString();
  const minutes = Math.floor((remainingTime % 3600) / 60).toString();
  const seconds = (remainingTime % 60).toString();

  return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
};

export const diffInSeconds = (date: string): number => {
  const endDate = dayjs(date);
  const diff = endDate.diff();
  return Math.round(diff / 1000);
};
