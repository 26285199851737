import { useQuery } from 'react-query';
import { ColorsClient } from 'api/clients';
import { mapColor } from '../map';
import { useAppSelector } from 'core/redux-store';
import { selectColorsLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (id: number, lastUpdated: number) => {
  return ['color', id, lastUpdated];
};

const useColor = (id: number) => {
  const lastUpdated = useAppSelector(selectColorsLastUpdated);
  const getColorFn = () => ColorsClient.getColor(id);
  const { isLoading, data } = useQuery(getQueryKey(id, lastUpdated), getColorFn);
  return { isLoading, color: data ? mapColor(data.data) : null };
};

export default useColor;
