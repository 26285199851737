import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { useColor } from 'features/colors/hooks';
import { ColorForm } from 'features/colors/hoc';
import { useParams } from 'react-router-dom';

const ColorEditPage: FC = () => {
  const params = useParams();
  const id = Number(params.id);
  const { color, isLoading } = useColor(id);

  return <Container>{!!color && !isLoading && <ColorForm color={color} />}</Container>;
};

export default ColorEditPage;
