import { OneTimer, OneTimerFormData } from '../types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { OneTimersClient } from 'api/clients';
import { mapOneTimerFormData, mapSaveOneTimerRequest } from '../map';
import { useAppDispatch } from 'core/redux-store';
import { showSuccessToast } from 'core/toast';
import useOneTimersTranslations from './useOneTimersTranslations';
import { useNavigate } from 'react-router-dom';
import { updateItems, updateOrders } from 'core/redux-store/global/slice';

const photoShape = yup.object().shape({
  name: yup.string().required(),
  size: yup.number().required(),
  value: yup.string().required(),
});

const oneTimerShape = yup.object().shape({
  photo: photoShape.nullable(),
  itemsIds: yup.array(yup.number()).required(),
  shoppingItemsIds: yup.array(yup.number()).required(),
});

const getSchema = () => oneTimerShape.required();

const useOneTimerForm = (orderNumber: string, oneTimer?: OneTimer) => {
  const { t } = useOneTimersTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { handleSubmit, control } = useForm<OneTimerFormData>({
    resolver: yupResolver(getSchema()),
    defaultValues: oneTimer ? mapOneTimerFormData(oneTimer) : undefined,
  });

  const { mutateAsync, isLoading } = useMutation(
    async (formData: OneTimerFormData) => {
      const request = mapSaveOneTimerRequest(formData);
      await OneTimersClient.addOneTimer({ ...request, oneTimerOrderNumber: orderNumber });
    },
    {
      onSuccess: () => {
        dispatch(updateOrders());
        dispatch(updateItems());
        showSuccessToast(t('one_timer_is_successfully_saved'));
        navigate('/orders');
      },
    }
  );

  const save = async (formData: OneTimerFormData) => {
    await mutateAsync(formData);
  };

  return { handleSubmit, save, control, isLoading };
};

export default useOneTimerForm;
