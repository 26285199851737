import { Order, OrdersFilter } from './types';
import httpClient from '../../httpClient';
import { DataResponse, ListRequest } from '../../types';

const baseUrl = '/admin/orders';

export const getOrders = (request: ListRequest<OrdersFilter>) => {
  const params = { ...request.filter };
  return httpClient.get<void, DataResponse<Order[]>>(baseUrl, { params });
};

export const getOrder = (number: string) => {
  return httpClient.get<void, DataResponse<Order>>(`${baseUrl}/${number}`);
};
