import { FC } from 'react';
import { CellProps } from 'react-table';
import { formatMoney } from 'utils/currency';

const PriceCell: FC<CellProps<any>> = (props) => {
  const { value: price, row } = props;
  const currency = row.original.currency;

  return <>{formatMoney(price, currency)}</>;
};

export default PriceCell;
