import { FC } from 'react';
import { CellProps } from 'react-table';
import { Outfit } from '../types';
import { useOutfitsTranslations } from '../hooks';

const EnabledFromCell: FC<CellProps<Outfit, string | null>> = (props) => {
  const { value: enabledFrom } = props;
  const { t } = useOutfitsTranslations();

  if (!enabledFrom) return t('none');
  return <>{enabledFrom}</>;
};

export default EnabledFromCell;
