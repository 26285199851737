import { FC } from 'react';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CellProps } from 'react-table';
import { User } from '../types';
import { useUsersTranslations } from '../hooks';
import { EyeFill, Trash } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { setUserToDelete } from '../slice';

const ActionsCell: FC<CellProps<User>> = (props) => {
  const { row } = props;
  const id = row.original.id;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useUsersTranslations();

  const handleOnDeletePress = () => {
    dispatch(setUserToDelete({ user: row.original }));
  };

  return (
    <Dropdown as={ButtonGroup}>
      <Button onClick={() => navigate(`details/${id}`)} size='sm' variant='white'>
        <EyeFill className='me-2' />
        {t('view')}
      </Button>
      <Dropdown.Toggle split variant='white' />
      <Dropdown.Menu>
        <Dropdown.Item onClick={handleOnDeletePress}>
          <Trash className='dropdown-item-icon me-1' />
          {t('delete')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ActionsCell;
