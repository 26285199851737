import React, { FC } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { useRevisionPlanForm, useRevisionPlansTranslations } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { DetailsForm, MetaForm } from '../components';
import { RevisionPlan } from '../types';

interface Props {
  revisionPlan?: RevisionPlan;
}

const RevisionPlanForm: FC<Props> = (props) => {
  const { revisionPlan } = props;
  const { t } = useRevisionPlansTranslations();
  const navigate = useNavigate();
  const { control, handleSubmit, save, isLoading } = useRevisionPlanForm(revisionPlan);

  return (
    <>
      <div className='page-header'>
        <Row className='align-items-center'>
          <Col>
            <h2 className='page-header-title'>
              {!!revisionPlan ? t('edit_revision_plan') : t('add_revision_plan')}
            </h2>
          </Col>
          <Col lg='auto'>
            <Button
              size='sm'
              className='me-2'
              onClick={() => navigate('/revision-plans')}
              disabled={isLoading}
              variant='white'
            >
              {t('back_to_revision_plans_list')}
            </Button>
            <Button size='sm' onClick={handleSubmit(save)} disabled={isLoading} variant='primary'>
              {t('save_revision_plan')}
            </Button>
          </Col>
        </Row>
      </div>
      <Row>
        <Col lg={8}>
          <DetailsForm control={control} />
        </Col>
        <Col lg={4}>
          <MetaForm control={control} />
        </Col>
      </Row>
    </>
  );
};

export default RevisionPlanForm;
