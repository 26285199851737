import { useQuery } from 'react-query';
import { SubscriptionsClient } from 'api/clients';
import { mapSubscription } from '../map';
import { useAppSelector } from 'core/redux-store';
import { selectSubscriptionsLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (id: number, lastUpdated: number) => {
  return ['subscription', id, lastUpdated];
};

const useSubscription = (id: number) => {
  const lastUpdated = useAppSelector(selectSubscriptionsLastUpdated);
  const getSubscriptionFn = () => SubscriptionsClient.getSubscription(id);
  const { isLoading, data } = useQuery(getQueryKey(id, lastUpdated), getSubscriptionFn);
  return { isLoading, subscription: data ? mapSubscription(data.data) : null };
};

export default useSubscription;
