import React, {
  DetailedHTMLProps,
  FC,
  ImgHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Buffer } from 'buffer';
import httpClient from 'api/httpClient';

type Props = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const NetworkImage: FC<Props> = (props) => {
  const { src, alt, ...rest } = props;
  const [image, setImage] = useState<string | undefined>(undefined);

  const loadImage = useCallback(async (source: string) => {
    const response = await httpClient.get<void, ArrayBuffer>(source, {
      responseType: 'arraybuffer',
    });
    const base64 = new Buffer(response).toString('base64');
    setImage(`data:image/jpeg;charset=utf-8;base64,${base64}`);
  }, []);

  useEffect(() => {
    if (src) loadImage(src);
  }, [src, loadImage]);

  return <img alt={alt} src={image} {...rest} />;
};

export default NetworkImage;
