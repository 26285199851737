import { ShoppingItem, ShoppingItemFormData } from '../types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { ShoppingItemsClient } from 'api/clients';
import { useEffect } from 'react';
import { mapShoppingItemFormData, mapSaveShoppingItemRequest } from '../map';
import { useAppDispatch } from 'core/redux-store';
import { showSuccessToast } from 'core/toast';
import useShoppingItemsTranslations from './useShoppingItemsTranslations';
import { useNavigate } from 'react-router-dom';
import { updateShoppingItems } from 'core/redux-store/global/slice';

const photoShape = yup.object().shape({
  name: yup.string().required(),
  size: yup.number().required(),
  value: yup.string().required(),
});

const shoppingItemShape = yup.object().shape({
  enabled: yup.boolean().required(),
  tips: yup.string().required(),
  link: yup.string().url().required(),
  photo: photoShape.nullable(),
});

const getSchema = () => shoppingItemShape.required();

const useShoppingItemForm = (shoppingItem?: ShoppingItem) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useShoppingItemsTranslations();

  const { handleSubmit, control, reset } = useForm<ShoppingItemFormData>({
    resolver: yupResolver(getSchema()),
  });

  useEffect(() => {
    if (!shoppingItem) return;
    reset(mapShoppingItemFormData(shoppingItem));
  }, [shoppingItem, reset]);

  const { mutateAsync, isLoading } = useMutation(
    async (formData: ShoppingItemFormData) => {
      const request = mapSaveShoppingItemRequest(formData);
      if (!shoppingItem) await ShoppingItemsClient.addShoppingItem(request);
      else await ShoppingItemsClient.editShoppingItem(shoppingItem.id, request);
    },
    {
      onSuccess: () => {
        dispatch(updateShoppingItems());
        showSuccessToast(t('shopping_item_is_successfully_saved'));
        navigate('/shopping-items');
      },
    }
  );

  const save = async (formData: ShoppingItemFormData) => {
    await mutateAsync(formData);
  };

  return { handleSubmit, save, control, isLoading };
};

export default useShoppingItemForm;
