import { useAppDispatch, useAppSelector } from 'core/redux-store';
import { useMutation } from 'react-query';
import { SubscriptionsClient } from 'api/clients';
import { resetSubscriptionToDelete } from '../slice';
import { showSuccessToast } from 'core/toast';
import useSubscriptionsTranslations from './useSubscriptionsTranslations';
import { useNavigate } from 'react-router-dom';
import { selectSubscriptionToDelete } from '../selectors';
import { updateSubscriptions } from 'core/redux-store/global/slice';

const useSubscriptionDelete = () => {
  const { t } = useSubscriptionsTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const subscriptionToDelete = useAppSelector(selectSubscriptionToDelete);

  const { mutateAsync: deleteSubscription, isLoading } = useMutation(
    async () =>
      subscriptionToDelete
        ? SubscriptionsClient.deleteSubscription(subscriptionToDelete.id)
        : undefined,
    {
      onSuccess: () => {
        dispatch(updateSubscriptions());
        dispatch(resetSubscriptionToDelete());
        showSuccessToast(t('subscription_is_successfully_deleted'));
        navigate('/subscriptions');
      },
    }
  );

  return { isLoading, deleteSubscription };
};

export default useSubscriptionDelete;
