import { FC } from 'react';
import { CellProps } from 'react-table';
import { useTranslation } from 'react-i18next';
import { BaseOrder } from 'api/clients/orders/types';
import Timer from '../Timer';

const TimerCell: FC<CellProps<any, string | null>> = (props) => {
  const { value: paid, row } = props;
  const order = row.original as BaseOrder;
  const status = order.status;
  const completionDate = order.completionDate;

  const { t } = useTranslation('common');

  if (!paid || !completionDate) return t('none');
  if (status === 'completed') return t('completed');

  return <Timer endDate={completionDate} />;
};

export default TimerCell;
