import React, { FC } from 'react';
import { useLoginForm } from '../hooks';
import { Controller } from 'react-hook-form';
import { Input } from 'components';
import { useAuthTranslations } from '../hooks';
import { Button } from 'react-bootstrap';

const LoginForm: FC = () => {
  const { t } = useAuthTranslations();
  const { login, control, handleSubmit, isLoading } = useLoginForm();

  return (
    <form>
      <div className='text-center'>
        <div className='mb-5'>
          <h1 className='display-5'>{t('sign_in')}</h1>
        </div>
      </div>
      <Controller
        control={control}
        name='username'
        render={({ field: { ref, ...rest }, fieldState: { error } }) => (
          <Input
            className='mb-4'
            required
            label={t('email')}
            type='email'
            placeholder='email@address.com'
            {...rest}
            error={error?.message}
          />
        )}
      />
      <Controller
        control={control}
        name='password'
        render={({ field: { ref, ...rest }, fieldState: { error } }) => (
          <Input
            className='mb-4'
            required
            label={t('password')}
            type='password'
            placeholder={t('enter_your_password')}
            {...rest}
            error={error?.message}
          />
        )}
      />
      <div className='d-grid'>
        <Button onClick={handleSubmit(login)} disabled={isLoading} variant='primary' size='lg'>
          {t('sign_in')}
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
