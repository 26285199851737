import { Control, Controller } from 'react-hook-form';
import { OneTimer, OneTimerFormData } from '../types';
import React, { FC } from 'react';
import { useOneTimersTranslations } from '../hooks';
import { Card } from 'react-bootstrap';
import { FileCard, PhotoControl } from 'components';
import { getAbsoluteFilePath } from 'features/outfits/utils';

interface Props {
  className?: string;
  control: Control<OneTimerFormData>;
  oneTimer?: OneTimer;
}

const MediaForm: FC<Props> = (props) => {
  const { control, className, oneTimer } = props;
  const { t } = useOneTimersTranslations();

  return (
    <Card className={className}>
      <Card.Header>
        <h5 className='card-header-title'>{t('media')}</h5>
      </Card.Header>
      <Card.Body>
        {!!oneTimer?.outfit.photo && (
          <FileCard
            className='mb-2'
            name={oneTimer.outfit.photo.name}
            src={getAbsoluteFilePath(oneTimer.outfit.photo.name)}
            size={oneTimer.outfit.photo.size}
          />
        )}
        <Controller
          defaultValue={null}
          control={control}
          name='photo'
          render={({ field: { onChange, value } }) => (
            <PhotoControl isDisabled={!!oneTimer} photo={value} onPhotoChange={onChange} />
          )}
        />
      </Card.Body>
    </Card>
  );
};

export default MediaForm;
