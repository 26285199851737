import { useAppDispatch } from 'core/redux-store';
import { logout as logoutAction } from '../slice';

const useLogout = () => {
  const dispatch = useAppDispatch();

  const logout = async () => {
    dispatch(logoutAction());
  };

  return { logout };
};

export default useLogout;
