import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { ShoppingItemFormData } from '../types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useShoppingItemsTranslations } from '../hooks';
import { FormGroup } from 'components';

interface Props {
  className?: string;
  control: Control<ShoppingItemFormData>;
}

const DetailsForm: FC<Props> = (props) => {
  const { control, className } = props;
  const { t } = useShoppingItemsTranslations();

  return (
    <Card className={className}>
      <Card.Header>
        <h5 className='card-header-title'>{t('shopping_item_details')}</h5>
      </Card.Header>
      <Card.Body>
        <Row className='mb-4'>
          <Col>
            <Controller
              defaultValue=''
              control={control}
              name='link'
              render={({ field, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('link')}>
                  <Form.Control isInvalid={!!error} {...field} type='text' />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Controller
              defaultValue=''
              control={control}
              name='tips'
              render={({ field, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('tips')}>
                  <Form.Control rows={4} isInvalid={!!error} {...field} as='textarea' type='text' />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DetailsForm;
