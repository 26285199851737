import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useUser } from 'features/users/hooks';
import { UserDetails } from 'features/users/hoc';

const UserDetailsPage: FC = () => {
  const params = useParams();
  const id = Number(params.id);
  const { user, isLoading } = useUser(id);

  return <Container>{!!user && !isLoading && <UserDetails user={user} />}</Container>;
};

export default UserDetailsPage;
