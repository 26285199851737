import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product } from './types';
import { logout } from 'features/auth/slice';

interface State {
  productToDelete: Product | null;
}

const initialState: State = {
  productToDelete: null,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProductToDelete: (state, action: PayloadAction<{ product: Product }>) => {
      state.productToDelete = action.payload.product;
    },
    resetProductToDelete: (state) => {
      state.productToDelete = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const { setProductToDelete, resetProductToDelete } = productsSlice.actions;
export const { reducer } = productsSlice;
