import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { TestimonialForm } from 'features/testimonials/hoc';

const TestimonialAddPage: FC = () => {
  return (
    <Container>
      <TestimonialForm />
    </Container>
  );
};

export default TestimonialAddPage;
