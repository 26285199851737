import React, { FC, useCallback, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { SubscriptionFormData } from '../types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useSubscriptionsTranslations } from '../hooks';
import { FormGroup } from 'components';
import Select from 'react-select';
import { SubscriptionType, SubscriptionDuration } from 'api/clients/subscriptions/types';

interface Props {
  className?: string;
  control: Control<SubscriptionFormData>;
}

const DetailsForm: FC<Props> = (props) => {
  const { control, className } = props;
  const { t } = useSubscriptionsTranslations();

  const subscriptionTypeOptions = useMemo<{ value: SubscriptionType; label: string }[]>(
    () => [
      { value: 'basic', label: t('basic') },
      { value: 'premium', label: t('premium') },
    ],
    [t]
  );

  const subscriptionDurationOptions = useMemo<{ value: SubscriptionDuration; label: string }[]>(
    () => [
      { value: 'monthly', label: t('monthly') },
      { value: 'yearly', label: t('yearly') },
    ],
    [t]
  );

  const findSubscriptionTypeOption = useCallback(
    (value: SubscriptionType) =>
      subscriptionTypeOptions.find(
        (subscriptionTypeOption) => subscriptionTypeOption.value === value
      ),
    [subscriptionTypeOptions]
  );

  const findSubscriptionDurationOption = useCallback(
    (value: SubscriptionDuration) =>
      subscriptionDurationOptions.find(
        (subscriptionDurationOption) => subscriptionDurationOption.value === value
      ),
    [subscriptionDurationOptions]
  );

  return (
    <Card className={className}>
      <Card.Header>
        <h5 className='card-header-title'>{t('subscription_details')}</h5>
      </Card.Header>
      <Card.Body>
        <Row className='mb-4'>
          <Col>
            <Controller
              defaultValue=''
              control={control}
              name='title'
              render={({ field, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('title')}>
                  <Form.Control isInvalid={!!error} {...field} type='text' />
                </FormGroup>
              )}
            />
          </Col>
          <Col>
            <Controller
              defaultValue=''
              control={control}
              name='sku'
              render={({ field, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('sku')}>
                  <Form.Control isInvalid={!!error} {...field} type='text' />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col>
            <Controller
              control={control}
              name='type'
              render={({ field: { value, onChange, ...rest }, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('type')}>
                  <Select
                    isClearable
                    placeholder={t('select_subscription_type')}
                    value={findSubscriptionTypeOption(value)}
                    options={subscriptionTypeOptions}
                    onChange={(data) => onChange(data?.value)}
                    {...rest}
                  />
                </FormGroup>
              )}
            />
          </Col>
          <Col>
            <Controller
              control={control}
              name='duration'
              render={({ field: { value, onChange, ...rest }, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('duration')}>
                  <Select
                    isClearable
                    placeholder={t('select_subscription_duration')}
                    value={findSubscriptionDurationOption(value)}
                    options={subscriptionDurationOptions}
                    onChange={(data) => onChange(data?.value)}
                    {...rest}
                  />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Controller
              defaultValue=''
              control={control}
              name='description'
              render={({ field, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('content')}>
                  <Form.Control rows={4} isInvalid={!!error} {...field} as='textarea' type='text' />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DetailsForm;
