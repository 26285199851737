import { useAppSelector } from 'core/redux-store';
import { useQuery } from 'react-query';
import { ProductsClient } from 'api/clients';
import { mapProduct } from '../map';
import { selectProductsLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (lastUpdated: number) => {
  return ['products', lastUpdated];
};

const useProducts = () => {
  const lastUpdated = useAppSelector(selectProductsLastUpdated);
  const { isLoading, data } = useQuery(getQueryKey(lastUpdated), ProductsClient.getProducts);
  return { isLoading, products: data?.data.map(mapProduct) ?? [], productsCount: data?.total ?? 0 };
};

export default useProducts;
