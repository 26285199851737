import { FC } from 'react';
import { CellProps } from 'react-table';
import { Item } from '../types';
import { useItemsTranslations } from '../hooks';
import { Color } from 'api/clients/colors/types';

const ColorsCell: FC<CellProps<Item, Color[]>> = (props) => {
  const { value: colors } = props;
  const { t } = useItemsTranslations();

  if (colors.length === 0) return t('none');

  return (
    <div className='d-flex flex-row align-items-center'>
      {colors.map((color) => (
        <div key={color.id} className='dot' style={{ backgroundColor: color.code }}></div>
      ))}
    </div>
  );
};

export default ColorsCell;
