import React, { FC } from 'react';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useWeeklyPlansTranslations } from '../hooks';
import { CurrencySelect, FormGroup } from 'components';
import { WeeklyOrder } from 'features/orders/types';
import { useOrdersTranslations } from '../../orders/hooks';
import { Link } from 'react-router-dom';

interface Props {
  weeklyOrder: WeeklyOrder;
  className?: string;
}

const MetaForm: FC<Props> = (props) => {
  const { weeklyOrder, className } = props;
  const { t } = useWeeklyPlansTranslations();
  const { t: ordersT } = useOrdersTranslations();

  return (
    <div className={className}>
      <Card className='mb-4'>
        <Card.Header>
          <h5 className='card-header-title'>{t('order_details')}</h5>
        </Card.Header>
        <Card.Body>
          <Row className='mb-3'>
            <Col>
              <FormGroup label={t('user')}>
                <Link to={`/users/details/${weeklyOrder.user.id}`}>{weeklyOrder.user.email}</Link>
              </FormGroup>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <FormGroup label={t('order_number')}>
                <Form.Control disabled value={weeklyOrder.number} type='text' />
              </FormGroup>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <FormGroup label={t('status')}>
                <Form.Control disabled value={ordersT(weeklyOrder.status)} type='text' />
              </FormGroup>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <FormGroup label={t('price')}>
                <InputGroup>
                  <Form.Control value={weeklyOrder.price.toFixed(2)} disabled type='number' />
                  <CurrencySelect isReadOnly value={weeklyOrder.currency} />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <FormGroup label={t('product')}>
                <Form.Control disabled value={weeklyOrder.product.title} type='text' />
              </FormGroup>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <FormGroup label={t('updated')}>
                <Form.Control disabled value={weeklyOrder.updated ?? undefined} type='text' />
              </FormGroup>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <FormGroup label={t('created')}>
                <Form.Control disabled value={weeklyOrder.created} type='text' />
              </FormGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <h5 className='card-header-title'>{t('weekly_plan_order_details')}</h5>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <FormGroup label={t('price_per_outfit')}>
                <InputGroup>
                  <Form.Control
                    value={weeklyOrder.pricePerOutfit.toFixed(2)}
                    disabled
                    type='number'
                  />
                  <CurrencySelect value={weeklyOrder.pricePerOutfitCurrency} />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default MetaForm;
