import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useOrder } from 'features/orders/hooks';
import { WeeklyOrder } from 'features/orders/types';
import { WeeklyPlanForm } from 'features/weekly-plans/hoc';

const WeeklyPlanAddPage: FC = () => {
  const params = useParams();
  const orderNumber = params.number as string;
  const { order, isLoading } = useOrder(orderNumber);

  return (
    <Container>
      {!!order && !isLoading && <WeeklyPlanForm weeklyOrder={order as WeeklyOrder} />}
    </Container>
  );
};

export default WeeklyPlanAddPage;
