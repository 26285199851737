import React, { FC, useEffect } from 'react';
import { Container, Card } from 'react-bootstrap';
import { LoginForm } from 'features/auth/hoc';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'core/redux-store';
import { selectIsLoggedIn } from 'features/auth/selectors';

const LoginPage: FC = () => {
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  useEffect(() => {
    if (isLoggedIn) navigate('/orders');
  }, [navigate, isLoggedIn]);

  return (
    <Container className='py-5 py-sm-7'>
      <div className='mx-auto' style={{ maxWidth: '30rem' }}>
        <Card className='card-lg mb-5'>
          <Card.Body>
            <LoginForm />
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default LoginPage;
