import { useAppSelector } from 'core/redux-store';
import { useQuery } from 'react-query';
import { ColorsClient } from 'api/clients';
import { mapColor } from '../map';
import { selectColorsLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (lastUpdated: number) => {
  return ['colors', lastUpdated];
};

const useColors = () => {
  const lastUpdated = useAppSelector(selectColorsLastUpdated);
  const { isLoading, data } = useQuery(getQueryKey(lastUpdated), ColorsClient.getColors);
  return { isLoading, colors: data?.data.map(mapColor) ?? [], colorsCount: data?.total ?? 0 };
};

export default useColors;
