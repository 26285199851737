import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { ProductForm } from 'features/products/hoc';

const ProductAddPage: FC = () => {
  return (
    <Container>
      <ProductForm />
    </Container>
  );
};

export default ProductAddPage;
