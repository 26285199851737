import React, { FC, useCallback } from 'react';
import { useRevisionPlanDelete, useRevisionPlansTranslations } from '../hooks';
import { useAppSelector } from 'core/redux-store';
import { selectRevisionPlanToDelete } from '../selectors';
import { resetRevisionPlanToDelete } from '../slice';
import { useDispatch } from 'react-redux';
import { ConfirmationModal } from 'components';

const RevisionPlanDeleteConfirmation: FC = () => {
  const { t } = useRevisionPlansTranslations();
  const dispatch = useDispatch();

  const { deleteRevisionPlan, isLoading } = useRevisionPlanDelete();
  const revisionPlanToDelete = useAppSelector(selectRevisionPlanToDelete);

  const handleOnConfirmationModalClose = useCallback(() => {
    dispatch(resetRevisionPlanToDelete());
  }, [dispatch]);

  return (
    <ConfirmationModal
      isActionsDisabled={isLoading}
      isVisible={!!revisionPlanToDelete}
      content={revisionPlanToDelete?.title}
      onClose={handleOnConfirmationModalClose}
      title={t('are_you_sure_want_to_delete_this_revision_plan')}
      confirmLabel={t('delete')}
      onConfirm={deleteRevisionPlan}
    />
  );
};

export default RevisionPlanDeleteConfirmation;
