import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { TestimonialFormData } from '../types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useTestimonialsTranslations } from '../hooks';
import { FormGroup } from 'components';

interface Props {
  className?: string;
  control: Control<TestimonialFormData>;
}

const DetailsForm: FC<Props> = (props) => {
  const { control, className } = props;
  const { t } = useTestimonialsTranslations();

  return (
    <Card className={className}>
      <Card.Header>
        <h5 className='card-header-title'>{t('testimonial_details')}</h5>
      </Card.Header>
      <Card.Body>
        <Row className='mb-4'>
          <Col>
            <Controller
              defaultValue=''
              control={control}
              name='fullName'
              render={({ field, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('full_name')}>
                  <Form.Control isInvalid={!!error} {...field} type='text' />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Controller
              defaultValue=''
              control={control}
              name='comment'
              render={({ field, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('comment')}>
                  <Form.Control rows={4} isInvalid={!!error} {...field} as='textarea' type='text' />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DetailsForm;
