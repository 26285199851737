import React, { FC } from 'react';

interface Props {
  code: string;
  label: string;
}

const ColorOption: FC<Props> = (props) => {
  const { code, label } = props;

  return (
    <div className='d-flex flex-row align-items-center'>
      <div className='dot me-2' style={{ backgroundColor: code }}></div>
      <span>{label}</span>
    </div>
  );
};

export default ColorOption;
