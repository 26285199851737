import { useQuery } from 'react-query';
import { UsersClient } from 'api/clients';
import { mapUser } from '../map';
import { useAppSelector } from 'core/redux-store';
import { selectUsersLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (id: number, lastUpdated: number) => {
  return ['user', id, lastUpdated];
};

const useUser = (id: number) => {
  const lastUpdated = useAppSelector(selectUsersLastUpdated);
  const getUserFn = () => UsersClient.getUser(id);
  const { isLoading, data } = useQuery(getQueryKey(id, lastUpdated), getUserFn);
  return { isLoading, user: data ? mapUser(data.data) : null };
};

export default useUser;
