import React, { FC } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { useFaqForm, useFaqsTranslations } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { DetailsForm, MetaForm } from '../components';
import { Faq } from '../types';

interface Props {
  faq?: Faq;
}

const FaqForm: FC<Props> = (props) => {
  const { faq } = props;
  const { t } = useFaqsTranslations();
  const navigate = useNavigate();
  const { control, handleSubmit, save, isLoading } = useFaqForm(faq);

  return (
    <>
      <div className='page-header'>
        <Row className='align-items-center'>
          <Col>
            <h2 className='page-header-title'>{!!faq ? t('edit_faq') : t('add_faq')}</h2>
          </Col>
          <Col lg='auto'>
            <Button
              size='sm'
              className='me-2'
              onClick={() => navigate('/faqs')}
              disabled={isLoading}
              variant='white'
            >
              {t('back_to_faqs_list')}
            </Button>
            <Button size='sm' onClick={handleSubmit(save)} disabled={isLoading} variant='primary'>
              {t('save_faq')}
            </Button>
          </Col>
        </Row>
      </div>
      <Row>
        <Col lg={8}>
          <DetailsForm control={control} />
        </Col>
        <Col lg={4}>
          <MetaForm control={control} />
        </Col>
      </Row>
    </>
  );
};

export default FaqForm;
