import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { useFaq } from 'features/faqs/hooks';
import { FaqForm } from 'features/faqs/hoc';
import { useParams } from 'react-router-dom';

const FaqEditPage: FC = () => {
  const params = useParams();
  const id = Number(params.id);
  const { faq, isLoading } = useFaq(id);

  return <Container>{!!faq && !isLoading && <FaqForm faq={faq} />}</Container>;
};

export default FaqEditPage;
