import { DataResponse, ListRequest, ListResponse } from '../../types';
import { Outfit, OutfitsFilter } from './types';
import httpClient from '../../httpClient';

const baseUrl = '/admin/outfits';

export const getOutfits = (request: ListRequest<OutfitsFilter>) => {
  const params = { ...request.filter };
  return httpClient.get<void, ListResponse<Outfit>>(baseUrl, { params });
};

export const getOutfit = (id: number) => {
  return httpClient.get<void, DataResponse<Outfit>>(`${baseUrl}/${id}`);
};
