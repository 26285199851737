import { Faq as ApiFaq, SaveFaqRequest } from 'api/clients/faqs/types';
import { Faq, FaqFormData } from './types';
import moment from 'moment';

export const mapFaq = (faq: ApiFaq): Faq => ({
  ...faq,
  updated: faq.updated ? moment(faq.updated).format('YYYY-MM-DD HH:mm:ss') : null,
  created: moment(faq.created).format('YYYY-MM-DD HH:mm:ss'),
});

export const mapFaqFormData = (faq: Faq): FaqFormData => ({
  title: faq.title,
  enabled: faq.enabled,
  content: faq.content,
});

export const mapSaveFaqRequest = (formData: FaqFormData): SaveFaqRequest => formData;
