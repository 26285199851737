import { useAppSelector } from 'core/redux-store';
import { useQuery } from 'react-query';
import { OrdersClient } from 'api/clients';
import { mapOrder } from '../map';
import { OrdersFilter } from 'api/clients/orders/types';
import { selectOrdersLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (filter: OrdersFilter, lastUpdated: number) => {
  return ['orders', filter, lastUpdated];
};

const useOrders = (filter: OrdersFilter = {}) => {
  const lastUpdated = useAppSelector(selectOrdersLastUpdated);
  const getOrdersFn = () => OrdersClient.getOrders({ filter });
  const { isLoading, data } = useQuery(getQueryKey(filter, lastUpdated), getOrdersFn);
  return { isLoading, orders: data?.data.map(mapOrder) ?? [] };
};

export default useOrders;
