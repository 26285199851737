import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Subscription } from './types';
import { logout } from 'features/auth/slice';

interface State {
  subscriptionToDelete: Subscription | null;
}

const initialState: State = {
  subscriptionToDelete: null,
};

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setSubscriptionToDelete: (state, action: PayloadAction<{ subscription: Subscription }>) => {
      state.subscriptionToDelete = action.payload.subscription;
    },
    resetSubscriptionToDelete: (state) => {
      state.subscriptionToDelete = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const { setSubscriptionToDelete, resetSubscriptionToDelete } = subscriptionsSlice.actions;
export const { reducer } = subscriptionsSlice;
