import {
  Testimonial as ApiTestimonial,
  SaveTestimonialRequest,
} from 'api/clients/testimonials/types';
import { Testimonial, TestimonialFormData } from './types';
import moment from 'moment';

export const mapTestimonial = (testimonial: ApiTestimonial): Testimonial => ({
  ...testimonial,
  updated: testimonial.updated ? moment(testimonial.updated).format('YYYY-MM-DD HH:mm:ss') : null,
  created: moment(testimonial.created).format('YYYY-MM-DD HH:mm:ss'),
});

export const mapTestimonialFormData = (testimonial: Testimonial): TestimonialFormData => ({
  fullName: testimonial.fullName,
  enabled: testimonial.enabled,
  comment: testimonial.comment,
  photo: null,
});

export const mapSaveTestimonialRequest = (
  formData: TestimonialFormData
): SaveTestimonialRequest => ({
  ...formData,
  photo: formData.photo?.value ?? null,
});
