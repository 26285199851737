import { useAppSelector } from 'core/redux-store';
import { useQuery } from 'react-query';
import { OutfitsClient } from 'api/clients';
import { mapOutfit } from '../map';
import { OutfitsFilter } from 'api/clients/outfits/types';
import { selectOutfitsLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (filter: OutfitsFilter, lastUpdated: number) => {
  return ['outfits', filter, lastUpdated];
};

const useOutfits = (filter: OutfitsFilter) => {
  const lastUpdated = useAppSelector(selectOutfitsLastUpdated);
  const getOutfitsFn = () => OutfitsClient.getOutfits({ filter });
  const { isLoading, data } = useQuery(getQueryKey(filter, lastUpdated), getOutfitsFn);
  return { isLoading, outfits: data?.data.map(mapOutfit) ?? [], outfitsCount: data?.total ?? 0 };
};

export default useOutfits;
