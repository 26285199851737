import { useAppDispatch } from 'core/redux-store';
import { useMutation } from 'react-query';
import { UsersClient } from 'api/clients';
import { showSuccessToast } from 'core/toast';
import useUsersTranslations from './useUsersTranslations';
import { updateUsers } from 'core/redux-store/global/slice';
import { SaveUserRolesMutateRequest, User } from '../types';
import { SaveUserRolesRequest, UserRoles } from 'api/clients/users/types';

const useUserEditRoles = () => {
  const { t } = useUsersTranslations();
  const dispatch = useAppDispatch();

  const { mutateAsync: editUserRoles, isLoading } = useMutation(
    (data: SaveUserRolesMutateRequest) => UsersClient.editUserRoles(data.id, data.request),
    {
      onSuccess: () => {
        dispatch(updateUsers());
        showSuccessToast(t('user_roles_are_successfully_updated'));
      },
    }
  );

  const addAdminRole = (user: User) => {
    if (user.isAdmin) return;
    const roles: UserRoles[] = [...user.roles, 'ROLE_ADMIN'];
    const request: SaveUserRolesRequest = { roles };
    return editUserRoles({ id: user.id, request });
  };

  const removeAdminRole = (user: User) => {
    if (!user.isAdmin) return;
    const roles: UserRoles[] = user.roles.filter((role) => role !== 'ROLE_ADMIN');
    const request: SaveUserRolesRequest = { roles };
    return editUserRoles({ id: user.id, request });
  };

  return { isLoading, addAdminRole, removeAdminRole };
};

export default useUserEditRoles;
