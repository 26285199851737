import { Product as ApiProduct, SaveProductRequest } from 'api/clients/products/types';
import { Product, ProductFormData } from './types';
import moment from 'moment';

export const mapProduct = (product: ApiProduct): Product => ({
  ...product,
  updated: product.updated ? moment(product.updated).format('YYYY-MM-DD HH:mm:ss') : null,
  created: moment(product.created).format('YYYY-MM-DD HH:mm:ss'),
});

export const mapProductFormData = (product: Product): ProductFormData => ({
  title: product.title,
  enabled: product.enabled,
  description: product.description,
  durationInHours: product.durationInHours,
  type: product.type,
  currency: product.currency,
  price: product.price,
});

export const mapSaveProductRequest = (formData: ProductFormData): SaveProductRequest => ({
  ...formData,
  price: formData.type === 'revision' ? null : formData.price,
  currency: formData.type === 'revision' ? null : formData.currency,
});
