import React, { FC, useCallback, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { ColorFormData } from '../types';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useColors, useColorsTranslations } from '../hooks';
import { FormGroup, ColorOption } from 'components';
import Select from 'react-select';

interface Props {
  className?: string;
  control: Control<ColorFormData>;
}

const DetailsForm: FC<Props> = (props) => {
  const { control, className } = props;
  const { t } = useColorsTranslations();
  const { colors, isLoading: isColorsLoading } = useColors();

  const colorsOptions = useMemo(
    () => colors.map((color) => ({ value: color.id, label: color.title, code: color.code })),
    [colors]
  );

  const findColorsOptions = useCallback(
    (ids: number[]) => colorsOptions.filter((option) => ids.includes(option.value)),
    [colorsOptions]
  );

  return (
    <Card className={className}>
      <Card.Header>
        <h5 className='card-header-title'>{t('color_details')}</h5>
      </Card.Header>
      <Card.Body>
        <Row className='mb-4'>
          <Col>
            <Controller
              defaultValue=''
              control={control}
              name='title'
              render={({ field, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('title')}>
                  <Form.Control isInvalid={!!error} {...field} type='text' />
                </FormGroup>
              )}
            />
          </Col>
          <Col>
            <Controller
              defaultValue=''
              control={control}
              name='code'
              render={({ field, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('code')}>
                  <Form.Control isInvalid={!!error} {...field} type='text' />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Controller
              defaultValue={[]}
              control={control}
              name='matchingColorsIds'
              render={({ field: { value, onChange, ...rest }, fieldState: { error } }) => (
                <>
                  <FormGroup className='mb-2' error={error?.message} label={t('matching_colors')}>
                    <Select
                      isMulti
                      isLoading={isColorsLoading}
                      isClearable
                      formatOptionLabel={(option) => <ColorOption {...option} />}
                      placeholder={t('select_matching_colors')}
                      options={colorsOptions}
                      value={findColorsOptions(value)}
                      onChange={(options) => onChange(options.map((option) => option.value))}
                      {...rest}
                    />
                  </FormGroup>
                  <Button
                    variant='secondary'
                    size='sm'
                    disabled={isColorsLoading}
                    onClick={() => onChange(colors.map((color) => color.id))}
                  >
                    {t('set_all_colors')}
                  </Button>
                </>
              )}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DetailsForm;
