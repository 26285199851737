import { FC } from 'react';
import { CellProps } from 'react-table';
import { Product } from '../types';
import { ProductType } from 'api/clients/products/types';
import { useProductsTranslations } from '../hooks';

const TypeCell: FC<CellProps<Product, ProductType>> = (props) => {
  const { value: type } = props;
  const { t } = useProductsTranslations();

  return <>{t(type)}</>;
};

export default TypeCell;
