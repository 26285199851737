import { QueryClient } from 'react-query';
import { defaultStaleTime } from './constants';
import { showBaseErrorToast } from '../toast';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      onError: () => showBaseErrorToast(),
      staleTime: defaultStaleTime,
    },
    mutations: {
      onError: () => showBaseErrorToast(),
    },
  },
});

export default queryClient;
