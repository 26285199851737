import { FC } from 'react';
import { Form } from 'react-bootstrap';
import { CellProps } from 'react-table';
import { Outfit } from '../types';

const CreatedByCell: FC<CellProps<Outfit, boolean>> = (props) => {
  const { value: isCreatedBySos } = props;

  return <Form.Switch checked={isCreatedBySos} readOnly type='checkbox' />;
};

export default CreatedByCell;
