import React, { FC } from 'react';
import { Col, Row, Button, Tab, Tabs } from 'react-bootstrap';
import { useWeeklyPlanForm, useWeeklyPlansTranslations } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { MetaForm, OutfitForm } from '../components';
import { WeeklyOrder } from 'features/orders/types';
import { useFieldArray } from 'react-hook-form';
import { WeeklyPlan } from '../types';

interface Props {
  weeklyOrder: WeeklyOrder;
  weeklyPlan?: WeeklyPlan;
}

const WeeklyPlanForm: FC<Props> = (props) => {
  const { weeklyOrder, weeklyPlan } = props;
  const { t } = useWeeklyPlansTranslations();
  const navigate = useNavigate();

  const { control, handleSubmit, save, isLoading } = useWeeklyPlanForm(
    weeklyOrder.number,
    weeklyPlan
  );

  const { fields } = useFieldArray({ control, name: 'outfits' });

  return (
    <>
      <div className='page-header'>
        <Row className='align-items-center'>
          <Col>
            <h2 className='page-header-title'>
              {weeklyPlan ? t('weekly_plan_details') : t('add_weekly_plan')}
            </h2>
          </Col>
          <Col lg='auto'>
            <Button
              size='sm'
              onClick={() => navigate('/orders')}
              disabled={isLoading}
              variant='white'
            >
              {t('back_to_orders_list')}
            </Button>
            {!weeklyPlan && (
              <>
                <Button
                  size='sm'
                  className='ms-2'
                  onClick={() => navigate('/shopping-items/add')}
                  variant='white'
                >
                  {t('add_shopping_item')}
                </Button>
                <Button
                  className='ms-2'
                  size='sm'
                  onClick={handleSubmit(save)}
                  disabled={isLoading}
                  variant='primary'
                >
                  {t('save_weekly_plan')}
                </Button>
              </>
            )}
          </Col>
        </Row>
      </div>
      <Row className='mb-3'>
        <Col lg={8}>
          <Tabs defaultActiveKey='outfit_0' className='mb-3'>
            {fields.map((field, index) => (
              <Tab
                key={field.id}
                eventKey={`outfit_${index}`}
                title={t('outfit', { index: index + 1 })}
              >
                <OutfitForm
                  weeklyPlan={weeklyPlan}
                  weeklyOrder={weeklyOrder}
                  control={control}
                  index={index}
                  field={field}
                />
              </Tab>
            ))}
          </Tabs>
        </Col>
        <Col lg={4}>
          <MetaForm weeklyOrder={weeklyOrder} />
        </Col>
      </Row>
    </>
  );
};

export default WeeklyPlanForm;
