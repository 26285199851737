import React, { FC } from 'react';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useRevisionsTranslations } from '../hooks';
import { CurrencySelect, FormGroup } from 'components';
import { RevisionOrder } from 'features/orders/types';
import { useOrdersTranslations } from '../../orders/hooks';
import { Link } from 'react-router-dom';

interface Props {
  revisionOrder: RevisionOrder;
  className?: string;
}

const MetaForm: FC<Props> = (props) => {
  const { revisionOrder, className } = props;
  const { t } = useRevisionsTranslations();
  const { t: ordersT } = useOrdersTranslations();

  return (
    <Card className={className}>
      <Card.Header>
        <h5 className='card-header-title'>{t('order_details')}</h5>
      </Card.Header>
      <Card.Body>
        <Row className='mb-3'>
          <Col>
            <FormGroup label={t('user')}>
              <Link to={`/users/details/${revisionOrder.user.id}`}>{revisionOrder.user.email}</Link>
            </FormGroup>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <FormGroup label={t('order_number')}>
              <Form.Control disabled value={revisionOrder.number} type='text' />
            </FormGroup>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <FormGroup label={t('status')}>
              <Form.Control disabled value={ordersT(revisionOrder.status)} type='text' />
            </FormGroup>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <FormGroup label={t('price')}>
              <InputGroup>
                <Form.Control value={revisionOrder.price.toFixed(2)} disabled type='number' />
                <CurrencySelect isReadOnly value={revisionOrder.currency} />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <FormGroup label={t('product')}>
              <Form.Control disabled value={revisionOrder.product.title} type='text' />
            </FormGroup>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <FormGroup label={t('revision_plan')}>
              <Form.Control disabled value={revisionOrder.revisionPlan.title} type='text' />
            </FormGroup>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <FormGroup label={t('updated')}>
              <Form.Control disabled value={revisionOrder.updated ?? undefined} type='text' />
            </FormGroup>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <FormGroup label={t('created')}>
              <Form.Control disabled value={revisionOrder.created} type='text' />
            </FormGroup>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default MetaForm;
