import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { useRevisionPlan } from 'features/revision-plans/hooks';
import { RevisionPlanForm } from 'features/revision-plans/hoc';
import { useParams } from 'react-router-dom';

const RevisionPlanEditPage: FC = () => {
  const params = useParams();
  const id = Number(params.id);
  const { revisionPlan, isLoading } = useRevisionPlan(id);

  return (
    <Container>
      {!!revisionPlan && !isLoading && <RevisionPlanForm revisionPlan={revisionPlan} />}
    </Container>
  );
};

export default RevisionPlanEditPage;
