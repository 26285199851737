import React, { FC, useCallback } from 'react';
import { useTestimonialDelete, useTestimonialsTranslations } from '../hooks';
import { useAppSelector } from 'core/redux-store';
import { selectTestimonialToDelete } from '../selectors';
import { resetTestimonialToDelete } from '../slice';
import { useDispatch } from 'react-redux';
import { ConfirmationModal } from 'components';

const TestimonialDeleteConfirmation: FC = () => {
  const { t } = useTestimonialsTranslations();
  const dispatch = useDispatch();

  const { deleteTestimonial, isLoading } = useTestimonialDelete();
  const testimonialToDelete = useAppSelector(selectTestimonialToDelete);

  const handleOnConfirmationModalClose = useCallback(() => {
    dispatch(resetTestimonialToDelete());
  }, [dispatch]);

  return (
    <ConfirmationModal
      isActionsDisabled={isLoading}
      isVisible={!!testimonialToDelete}
      content={testimonialToDelete?.comment}
      onClose={handleOnConfirmationModalClose}
      title={t('are_you_sure_want_to_delete_this_testimonial')}
      confirmLabel={t('delete')}
      onConfirm={deleteTestimonial}
    />
  );
};

export default TestimonialDeleteConfirmation;
