import { useQuery } from 'react-query';
import { FaqsClient } from 'api/clients';
import { mapFaq } from '../map';
import { useAppSelector } from 'core/redux-store';
import { selectFaqsLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (id: number, lastUpdated: number) => {
  return ['faq', id, lastUpdated];
};

const useFaq = (id: number) => {
  const lastUpdated = useAppSelector(selectFaqsLastUpdated);
  const getFaqFn = () => FaqsClient.getFaq(id);
  const { isLoading, data } = useQuery(getQueryKey(id, lastUpdated), getFaqFn);
  return { isLoading, faq: data ? mapFaq(data.data) : null };
};

export default useFaq;
