import React, { FC } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';
import classNames from 'classnames';

interface Props extends Omit<FormControlProps, 'value'> {
  label: string;
  error?: string;
  required?: boolean;
  value?: string | string[] | number | null;
}

const Input: FC<Props> = (props) => {
  const { label, error, value, required, className, ...rest } = props;

  return (
    <Form.Group className={classNames('form-group', className)}>
      <Form.Label className={classNames({ required })}>{label}</Form.Label>
      <Form.Control size='lg' value={value ?? ''} isInvalid={!!error} {...rest} />
      <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default Input;
