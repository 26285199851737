import React, { FC } from 'react';
import { ItemStatus } from 'api/clients/items/types';
import { Item } from 'features/items/types';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useRevisionsTranslations } from '../hooks';
import { getAbsoluteFilePath } from 'features/items/utils';
import { NetworkImage } from 'components';

interface Props {
  item: Item;
  value: ItemStatus;
  onChange: (value: ItemStatus) => void;
  isDisabled: boolean;
}

const ItemStatusChoice: FC<Props> = (props) => {
  const { item, value, onChange, isDisabled } = props;
  const { t } = useRevisionsTranslations();

  return (
    <Card>
      <Card.Body>
        <Row className='mb-3'>
          <Col className='text-center'>
            <NetworkImage
              className='item-image'
              alt={item.photo.name}
              src={getAbsoluteFilePath(item.photo.name)}
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <div className='d-flex flex-row align-items-center justify-content-between'>
          <Button
            disabled={value === 'rejected' || isDisabled}
            size='sm'
            className='me-2'
            onClick={() => onChange('rejected')}
            variant={value === 'rejected' ? 'danger' : 'outline-danger'}
          >
            {t('reject')}
          </Button>
          <Button
            disabled={value === 'active' || isDisabled}
            size='sm'
            className='me-2'
            onClick={() => onChange('active')}
            variant={value === 'active' ? 'secondary' : 'outline-secondary'}
          >
            {t('default')}
          </Button>
          <Button
            disabled={value === 'approved' || isDisabled}
            size='sm'
            className='me-2'
            onClick={() => onChange('approved')}
            variant={value === 'approved' ? 'success' : 'outline-success'}
          >
            {t('approve')}
          </Button>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default ItemStatusChoice;
