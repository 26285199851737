import httpClient from '../../httpClient';
import { ListResponse, DataResponse } from '../../types';
import { RevisionPlan, SaveRevisionPlanRequest } from './types';

const baseUrl = '/admin/revision-plans';

export const getRevisionPlans = () => {
  return httpClient.get<void, ListResponse<RevisionPlan>>(baseUrl);
};

export const getRevisionPlan = (id: number) => {
  return httpClient.get<void, DataResponse<RevisionPlan>>(`${baseUrl}/${id}`);
};

export const addRevisionPlan = async (request: SaveRevisionPlanRequest) => {
  return httpClient.post<SaveRevisionPlanRequest, DataResponse<RevisionPlan>>(baseUrl, request);
};

export const editRevisionPlan = async (id: number, request: SaveRevisionPlanRequest) => {
  return httpClient.put<SaveRevisionPlanRequest, DataResponse<RevisionPlan>>(
    `${baseUrl}/${id}`,
    request
  );
};

export const deleteRevisionPlan = (id: number) => {
  return httpClient.delete(`${baseUrl}/${id}`);
};
