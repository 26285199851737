import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { ColorForm } from 'features/colors/hoc';

const ColorAddPage: FC = () => {
  return (
    <Container>
      <ColorForm />
    </Container>
  );
};

export default ColorAddPage;
