import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { WeeklyPlanForm } from 'features/weekly-plans/hoc';
import { useOrder } from 'features/orders/hooks';
import { WeeklyOrder } from 'features/orders/types';
import { useWeeklyPlan } from 'features/weekly-plans/hooks';

const WeeklyPlanDetailsPage: FC = () => {
  const params = useParams();
  const orderNumber = params.number as string;
  const { order, isLoading: isOrderLoading } = useOrder(orderNumber);
  const { weeklyPlan, isLoading: isWeeklyPlanLoading } = useWeeklyPlan(orderNumber);

  return (
    <Container>
      {!!order && !isOrderLoading && !isWeeklyPlanLoading && !!weeklyPlan && (
        <WeeklyPlanForm weeklyPlan={weeklyPlan ?? undefined} weeklyOrder={order as WeeklyOrder} />
      )}
    </Container>
  );
};

export default WeeklyPlanDetailsPage;
