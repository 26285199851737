import { useQuery } from 'react-query';
import { ClassificatorsClient } from 'api/clients';

export const getQueryKey = () => {
  return ['occasions'];
};

const useOccasions = () => {
  const { data, isLoading } = useQuery(getQueryKey(), ClassificatorsClient.getOccasions);
  return { occasions: data ? data.data : [], isLoading };
};

export default useOccasions;
