import { useQuery } from 'react-query';
import { ShoppingItemsClient } from 'api/clients';
import { mapShoppingItem } from '../map';
import { useAppSelector } from 'core/redux-store';
import { selectShoppingItemsLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (id: number, lastUpdated: number) => {
  return ['shopping-item', id, lastUpdated];
};

const useShoppingItem = (id: number) => {
  const lastUpdated = useAppSelector(selectShoppingItemsLastUpdated);
  const getShoppingItemFn = () => ShoppingItemsClient.getShoppingItem(id);
  const { isLoading, data } = useQuery(getQueryKey(id, lastUpdated), getShoppingItemFn);
  return { isLoading, shoppingItem: data ? mapShoppingItem(data.data) : null };
};

export default useShoppingItem;
