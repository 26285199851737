import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { ProductFormData } from '../types';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useProductsTranslations } from '../hooks';
import { CurrencySelect, FormGroup } from 'components';

interface Props {
  control: Control<ProductFormData>;
  className?: string;
}

const MetaForm: FC<Props> = (props) => {
  const { control, className } = props;
  const { t } = useProductsTranslations();

  return (
    <Card className={className}>
      <Card.Header>
        <h5 className='card-header-title'>{t('meta')}</h5>
      </Card.Header>
      <Card.Body>
        <Row className='mb-3'>
          <Col>
            <Controller
              defaultValue={false}
              control={control}
              name='enabled'
              render={({ field: { value, ...rest }, fieldState: { error } }) => (
                <FormGroup
                  row
                  help={t('determine_if_product_is_active')}
                  error={error?.message}
                  label={t('enabled')}
                >
                  <Form.Switch isInvalid={!!error} checked={value} {...rest} />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
        <hr className='my-4' />
        {control._formValues.type !== 'revision' && (
          <Row className='mb-3'>
            <Col>
              <Controller
                control={control}
                name='price'
                render={({ field: { value: priceValue, ...rest }, fieldState: { error } }) => (
                  <FormGroup error={error?.message} label={t('price')}>
                    <InputGroup>
                      <Form.Control
                        isInvalid={!!error}
                        value={priceValue ?? undefined}
                        {...rest}
                        type='number'
                      />
                      <Controller
                        defaultValue='eur'
                        control={control}
                        name='currency'
                        render={({ field: { value, onChange } }) => (
                          <CurrencySelect value={value} onChange={onChange} />
                        )}
                      />
                    </InputGroup>
                  </FormGroup>
                )}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Controller
              defaultValue={0}
              control={control}
              name='durationInHours'
              render={({ field: priceField, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('duration_in_hours')}>
                  <Form.Control isInvalid={!!error} {...priceField} type='number' />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default MetaForm;
