import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { RevisionPlanForm } from 'features/revision-plans/hoc';

const RevisionPlanAddPage: FC = () => {
  return (
    <Container>
      <RevisionPlanForm />
    </Container>
  );
};

export default RevisionPlanAddPage;
