import React, { FC } from 'react';
import { CellProps } from 'react-table';
import { User } from '../types';
import { useUsersTranslations } from '../hooks';
import { UserRoles } from 'api/clients/users/types';

const RolesCell: FC<CellProps<User, UserRoles[]>> = (props) => {
  const { value: roles } = props;
  const { t } = useUsersTranslations();

  return <>{roles.map((role) => t(role)).join(', ')}</>;
};

export default RolesCell;
