import React, { FC, useCallback } from 'react';
import { useShoppingItemDelete, useShoppingItemsTranslations } from '../hooks';
import { useAppSelector } from 'core/redux-store';
import { selectShoppingItemToDelete } from '../selectors';
import { resetShoppingItemToDelete } from '../slice';
import { useDispatch } from 'react-redux';
import { ConfirmationModal } from 'components';

const ShoppingItemDeleteConfirmation: FC = () => {
  const { t } = useShoppingItemsTranslations();
  const dispatch = useDispatch();

  const { deleteShoppingItem, isLoading } = useShoppingItemDelete();
  const shoppingItemToDelete = useAppSelector(selectShoppingItemToDelete);

  const handleOnConfirmationModalClose = useCallback(() => {
    dispatch(resetShoppingItemToDelete());
  }, [dispatch]);

  return (
    <ConfirmationModal
      isActionsDisabled={isLoading}
      isVisible={!!shoppingItemToDelete}
      content={shoppingItemToDelete?.link}
      onClose={handleOnConfirmationModalClose}
      title={t('are_you_sure_want_to_delete_this_shopping_item')}
      confirmLabel={t('delete')}
      onConfirm={deleteShoppingItem}
    />
  );
};

export default ShoppingItemDeleteConfirmation;
