import { DataResponse, ListResponse } from '../../types';
import { SaveUserRolesRequest, User } from './types';
import httpClient from '../../httpClient';

const baseUrl = '/admin/users';

export const getUsers = async () => {
  return httpClient.get<void, ListResponse<User>>(baseUrl);
};

export const deleteUser = (id: number) => {
  return httpClient.delete(`${baseUrl}/${id}`);
};

export const editUserRoles = (id: number, request: SaveUserRolesRequest) => {
  return httpClient.put(`${baseUrl}/${id}/roles`, request);
};

export const getUser = (id: number) => {
  return httpClient.get<void, DataResponse<User>>(`${baseUrl}/${id}`);
};
