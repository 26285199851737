import { useQuery } from 'react-query';
import { WeeklyPlansClient } from 'api/clients';
import { mapWeeklyPlan } from '../map';
import { useAppSelector } from 'core/redux-store';
import { selectOrdersLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (number: string, lastUpdated: number) => {
  return ['weekly-plan', number, lastUpdated];
};

const useWeeklyPlan = (number: string) => {
  const lastUpdated = useAppSelector(selectOrdersLastUpdated);
  const getWeeklyPlanFn = () => WeeklyPlansClient.getWeeklyPlanByOrderNumber(number);
  const { isLoading, data } = useQuery(getQueryKey(number, lastUpdated), getWeeklyPlanFn);
  return { isLoading, weeklyPlan: data ? mapWeeklyPlan(data.data) : null };
};

export default useWeeklyPlan;
