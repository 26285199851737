import { useAppDispatch, useAppSelector } from 'core/redux-store';
import { useMutation } from 'react-query';
import { RevisionPlansClient } from 'api/clients';
import { resetRevisionPlanToDelete } from '../slice';
import { showSuccessToast } from 'core/toast';
import useRevisionPlansTranslations from './useRevisionPlansTranslations';
import { useNavigate } from 'react-router-dom';
import { selectRevisionPlanToDelete } from '../selectors';
import { updateRevisionPlans } from 'core/redux-store/global/slice';

const useRevisionPlanDelete = () => {
  const { t } = useRevisionPlansTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const revisionPlanToDelete = useAppSelector(selectRevisionPlanToDelete);

  const { mutateAsync: deleteRevisionPlan, isLoading } = useMutation(
    async () =>
      revisionPlanToDelete
        ? RevisionPlansClient.deleteRevisionPlan(revisionPlanToDelete.id)
        : undefined,
    {
      onSuccess: () => {
        dispatch(updateRevisionPlans());
        dispatch(resetRevisionPlanToDelete());
        showSuccessToast(t('revision_plan_is_successfully_deleted'));
        navigate('/revision-plans');
      },
    }
  );

  return { isLoading, deleteRevisionPlan };
};

export default useRevisionPlanDelete;
