import { Color, ColorFormData } from '../types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { ColorsClient } from 'api/clients';
import { useEffect } from 'react';
import { mapColorFormData, mapSaveColorRequest } from '../map';
import { useAppDispatch } from 'core/redux-store';
import { showSuccessToast } from 'core/toast';
import useColorsTranslations from './useColorsTranslations';
import { useNavigate } from 'react-router-dom';
import { updateColors } from 'core/redux-store/global/slice';

const colorShape = yup.object().shape({
  enabled: yup.boolean().required(),
  title: yup.string().required(),
  code: yup.string().required(),
  matchingColorsIds: yup.array(yup.number()).required(),
});

const getSchema = () => colorShape.required();

const useColorForm = (color?: Color) => {
  const { t } = useColorsTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { handleSubmit, control, reset } = useForm<ColorFormData>({
    resolver: yupResolver(getSchema()),
  });

  useEffect(() => {
    if (!color) return;
    reset(mapColorFormData(color));
  }, [color, reset]);

  const { mutateAsync, isLoading } = useMutation(
    async (formData: ColorFormData) => {
      const request = mapSaveColorRequest(formData);
      if (!color) await ColorsClient.addColor(request);
      else await ColorsClient.editColor(color.id, request);
    },
    {
      onSuccess: () => {
        dispatch(updateColors());
        showSuccessToast(t('color_is_successfully_saved'));
        navigate('/colors');
      },
    }
  );

  const save = async (formData: ColorFormData) => {
    await mutateAsync(formData);
  };

  return { handleSubmit, save, control, isLoading };
};

export default useColorForm;
