import { Product, ProductFormData } from '../types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { ProductsClient } from 'api/clients';
import { useEffect } from 'react';
import { mapProductFormData, mapSaveProductRequest } from '../map';
import { useAppDispatch } from 'core/redux-store';
import { showSuccessToast } from 'core/toast';
import useProductsTranslations from './useProductsTranslations';
import { useNavigate } from 'react-router-dom';
import { updateProducts } from 'core/redux-store/global/slice';
import { ProductType } from 'api/clients/products/types';

const productShape = yup.object().shape({
  enabled: yup.boolean().required(),
  title: yup.string().required(),
  description: yup.string().required(),
  type: yup.string().required(),
  price: yup
    .number()
    .positive()
    .when('type', {
      is: (type: ProductType) => type === 'revision',
      then: yup.number().positive().nullable(),
      otherwise: yup.number().positive().required(),
    }),
  currency: yup.string().when('type', {
    is: (type: ProductType) => type === 'revision',
    then: yup.string().nullable(),
    otherwise: yup.string().required(),
  }),
  durationInHours: yup.number().positive().integer().required(),
});

const getSchema = () => productShape.required();

const useProductForm = (product?: Product) => {
  const { t } = useProductsTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { handleSubmit, control, reset } = useForm<ProductFormData>({
    resolver: yupResolver(getSchema()),
  });

  useEffect(() => {
    if (!product) return;
    reset(mapProductFormData(product));
  }, [product, reset]);

  const { mutateAsync, isLoading } = useMutation(
    async (formData: ProductFormData) => {
      const request = mapSaveProductRequest(formData);
      if (!product) await ProductsClient.addProduct(request);
      else await ProductsClient.editProduct(product.id, request);
    },
    {
      onSuccess: () => {
        dispatch(updateProducts());
        showSuccessToast(t('product_is_successfully_saved'));
        navigate('/products');
      },
    }
  );

  const save = async (formData: ProductFormData) => {
    await mutateAsync(formData);
  };

  return { handleSubmit, save, control, isLoading };
};

export default useProductForm;
