import { useAppDispatch, useAppSelector } from 'core/redux-store';
import { useMutation } from 'react-query';
import { ShoppingItemsClient } from 'api/clients';
import { resetShoppingItemToDelete } from '../slice';
import { showSuccessToast } from 'core/toast';
import useShoppingItemsTranslations from './useShoppingItemsTranslations';
import { useNavigate } from 'react-router-dom';
import { selectShoppingItemToDelete } from '../selectors';
import { updateShoppingItems } from 'core/redux-store/global/slice';

const useShoppingItemDelete = () => {
  const { t } = useShoppingItemsTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const shoppingItemToDelete = useAppSelector(selectShoppingItemToDelete);

  const { mutateAsync: deleteShoppingItem, isLoading } = useMutation(
    async () =>
      shoppingItemToDelete
        ? ShoppingItemsClient.deleteShoppingItem(shoppingItemToDelete.id)
        : undefined,
    {
      onSuccess: () => {
        dispatch(updateShoppingItems());
        dispatch(resetShoppingItemToDelete());
        showSuccessToast(t('shopping_item_is_successfully_deleted'));
        navigate('/shopping-items');
      },
    }
  );

  return { isLoading, deleteShoppingItem };
};

export default useShoppingItemDelete;
