import { createSlice } from '@reduxjs/toolkit';
import { logout } from 'features/auth/slice';

interface State {
  usersLastUpdated: number;
  testimonialsLastUpdated: number;
  shoppingItemsLastUpdated: number;
  productsLastUpdated: number;
  outfitsLastUpdated: number;
  ordersLastUpdated: number;
  itemsLastUpdated: number;
  faqsLastUpdated: number;
  colorsLastUpdated: number;
  revisionPlansUpdated: number;
  subscriptionsUpdated: number;
}

const initialState: State = {
  usersLastUpdated: Date.now(),
  testimonialsLastUpdated: Date.now(),
  shoppingItemsLastUpdated: Date.now(),
  productsLastUpdated: Date.now(),
  outfitsLastUpdated: Date.now(),
  ordersLastUpdated: Date.now(),
  itemsLastUpdated: Date.now(),
  faqsLastUpdated: Date.now(),
  colorsLastUpdated: Date.now(),
  revisionPlansUpdated: Date.now(),
  subscriptionsUpdated: Date.now(),
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    updateUsers: (state) => {
      state.usersLastUpdated = Date.now();
    },
    updateTestimonials: (state) => {
      state.testimonialsLastUpdated = Date.now();
    },
    updateShoppingItems: (state) => {
      state.shoppingItemsLastUpdated = Date.now();
    },
    updateProducts: (state) => {
      state.productsLastUpdated = Date.now();
    },
    updateOutfits: (state) => {
      state.outfitsLastUpdated = Date.now();
    },
    updateOrders: (state) => {
      state.ordersLastUpdated = Date.now();
    },
    updateItems: (state) => {
      state.itemsLastUpdated = Date.now();
    },
    updateFaqs: (state) => {
      state.faqsLastUpdated = Date.now();
    },
    updateColors: (state) => {
      state.colorsLastUpdated = Date.now();
    },
    updateRevisionPlans: (state) => {
      state.revisionPlansUpdated = Date.now();
    },
    updateSubscriptions: (state) => {
      state.subscriptionsUpdated = Date.now();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const {
  updateUsers,
  updateTestimonials,
  updateShoppingItems,
  updateProducts,
  updateOutfits,
  updateOrders,
  updateItems,
  updateFaqs,
  updateColors,
  updateRevisionPlans,
  updateSubscriptions,
} = globalSlice.actions;

export const { reducer } = globalSlice;
