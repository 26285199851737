import React, { FC } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import {
  FaqsPage,
  LoginPage,
  UsersPage,
  TestimonialsPage,
  ShoppingItemsPage,
  FaqEditPage,
  FaqAddPage,
  TestimonialAddPage,
  TestimonialEditPage,
  ShoppingItemEditPage,
  ShoppingItemAddPage,
  OrdersPage,
  ProductsPage,
  ProductAddPage,
  ProductEditPage,
  UserDetailsPage,
  RevisionAddPage,
  OneTimerAddPage,
  WeeklyPlanAddPage,
  ColorsPage,
  ColorAddPage,
  ColorEditPage,
  RevisionDetailsPage,
  OneTimerDetailsPage,
  WeeklyPlanDetailsPage,
  RevisionPlansPage,
  RevisionPlanAddPage,
  RevisionPlanEditPage,
  SubscriptionsPage,
  SubscriptionAddPage,
  SubscriptionEditPage,
} from 'pages';
import RequireAuth from './RequireAuth';
import { useProfile } from 'features/auth/hooks';
import { AuthenticatedLayout } from 'layouts';

const RootNavigator: FC = () => {
  useProfile();

  return (
    <main className='main'>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<LoginPage />} />
          <Route path='/' element={<AuthenticatedLayout />}>
            <Route
              path='/orders/:number/add-weekly-plan'
              element={
                <RequireAuth>
                  <WeeklyPlanAddPage />
                </RequireAuth>
              }
            />
            <Route
              path='/orders/:number/weekly-plan-details'
              element={
                <RequireAuth>
                  <WeeklyPlanDetailsPage />
                </RequireAuth>
              }
            />
            <Route
              path='/orders/:number/add-revision'
              element={
                <RequireAuth>
                  <RevisionAddPage />
                </RequireAuth>
              }
            />
            <Route
              path='/orders/:number/revision-details'
              element={
                <RequireAuth>
                  <RevisionDetailsPage />
                </RequireAuth>
              }
            />
            <Route
              path='/orders/:number/add-one-timer'
              element={
                <RequireAuth>
                  <OneTimerAddPage />
                </RequireAuth>
              }
            />
            <Route
              path='/orders/:number/one-timer-details'
              element={
                <RequireAuth>
                  <OneTimerDetailsPage />
                </RequireAuth>
              }
            />
            <Route
              path='/orders'
              element={
                <RequireAuth>
                  <OrdersPage />
                </RequireAuth>
              }
            />
            <Route
              path='/products'
              element={
                <RequireAuth>
                  <ProductsPage />
                </RequireAuth>
              }
            />
            <Route
              path='/products/add'
              element={
                <RequireAuth>
                  <ProductAddPage />
                </RequireAuth>
              }
            />
            <Route
              path='/products/edit/:id'
              element={
                <RequireAuth>
                  <ProductEditPage />
                </RequireAuth>
              }
            />
            <Route
              path='/subscriptions'
              element={
                <RequireAuth>
                  <SubscriptionsPage />
                </RequireAuth>
              }
            />
            <Route
              path='/subscriptions/add'
              element={
                <RequireAuth>
                  <SubscriptionAddPage />
                </RequireAuth>
              }
            />
            <Route
              path='/subscriptions/edit/:id'
              element={
                <RequireAuth>
                  <SubscriptionEditPage />
                </RequireAuth>
              }
            />
            <Route
              path='/revision-plans'
              element={
                <RequireAuth>
                  <RevisionPlansPage />
                </RequireAuth>
              }
            />
            <Route
              path='/revision-plans/add'
              element={
                <RequireAuth>
                  <RevisionPlanAddPage />
                </RequireAuth>
              }
            />
            <Route
              path='/revision-plans/edit/:id'
              element={
                <RequireAuth>
                  <RevisionPlanEditPage />
                </RequireAuth>
              }
            />
            <Route
              path='/users'
              element={
                <RequireAuth>
                  <UsersPage />
                </RequireAuth>
              }
            />
            <Route
              path='/users/details/:id'
              element={
                <RequireAuth>
                  <UserDetailsPage />
                </RequireAuth>
              }
            />
            <Route
              path='/faqs'
              element={
                <RequireAuth>
                  <FaqsPage />
                </RequireAuth>
              }
            />
            <Route
              path='/faqs/add'
              element={
                <RequireAuth>
                  <FaqAddPage />
                </RequireAuth>
              }
            />
            <Route
              path='/faqs/edit/:id'
              element={
                <RequireAuth>
                  <FaqEditPage />
                </RequireAuth>
              }
            />
            <Route
              path='/colors'
              element={
                <RequireAuth>
                  <ColorsPage />
                </RequireAuth>
              }
            />
            <Route
              path='/colors/add'
              element={
                <RequireAuth>
                  <ColorAddPage />
                </RequireAuth>
              }
            />
            <Route
              path='/colors/edit/:id'
              element={
                <RequireAuth>
                  <ColorEditPage />
                </RequireAuth>
              }
            />
            <Route
              path='/testimonials'
              element={
                <RequireAuth>
                  <TestimonialsPage />
                </RequireAuth>
              }
            />
            <Route
              path='/testimonials/add'
              element={
                <RequireAuth>
                  <TestimonialAddPage />
                </RequireAuth>
              }
            />
            <Route
              path='/testimonials/edit/:id'
              element={
                <RequireAuth>
                  <TestimonialEditPage />
                </RequireAuth>
              }
            />
            <Route
              path='/shopping-items'
              element={
                <RequireAuth>
                  <ShoppingItemsPage />
                </RequireAuth>
              }
            />
            <Route
              path='/shopping-items/add'
              element={
                <RequireAuth>
                  <ShoppingItemAddPage />
                </RequireAuth>
              }
            />
            <Route
              path='/shopping-items/edit/:id'
              element={
                <RequireAuth>
                  <ShoppingItemEditPage />
                </RequireAuth>
              }
            />
            <Route path='/' element={<Navigate replace to='/orders' />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </main>
  );
};

export default RootNavigator;
