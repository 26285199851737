import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { useProduct } from 'features/products/hooks';
import { ProductForm } from 'features/products/hoc';
import { useParams } from 'react-router-dom';

const ProductEditPage: FC = () => {
  const params = useParams();
  const id = Number(params.id);
  const { product, isLoading } = useProduct(id);

  return <Container>{!!product && !isLoading && <ProductForm product={product} />}</Container>;
};

export default ProductEditPage;
