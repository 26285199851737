import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { useShoppingItem } from 'features/shopping-items/hooks';
import { ShoppingItemForm } from 'features/shopping-items/hoc';
import { useParams } from 'react-router-dom';

const ShoppingItemEditPage: FC = () => {
  const params = useParams();
  const id = Number(params.id);
  const { shoppingItem, isLoading } = useShoppingItem(id);

  return (
    <Container>
      {!!shoppingItem && !isLoading && <ShoppingItemForm shoppingItem={shoppingItem} />}
    </Container>
  );
};

export default ShoppingItemEditPage;
