import httpClient from '../../httpClient';
import { ListResponse, DataResponse } from '../../types';
import { Product, SaveProductRequest } from './types';

const baseUrl = '/admin/products';

export const getProducts = () => {
  return httpClient.get<void, ListResponse<Product>>(baseUrl);
};

export const getProduct = (id: number) => {
  return httpClient.get<void, DataResponse<Product>>(`${baseUrl}/${id}`);
};

export const addProduct = async (request: SaveProductRequest) => {
  return httpClient.post<SaveProductRequest, DataResponse<Product>>(baseUrl, request);
};

export const editProduct = async (id: number, request: SaveProductRequest) => {
  return httpClient.put<SaveProductRequest, DataResponse<Product>>(`${baseUrl}/${id}`, request);
};

export const deleteProduct = (id: number) => {
  return httpClient.delete(`${baseUrl}/${id}`);
};
