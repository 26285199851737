import React, { FC } from 'react';
import { User } from '../types';
import classNames from 'classnames';
import { getAbsoluteFacePhotoFilePath, getAbsoluteFullHeightPhotoFilePath } from '../utils';
import { useUsersTranslations } from '../hooks';
import { ListGroup } from 'react-bootstrap';
import { NetworkImage } from 'components';

interface Props {
  className?: string;
  user: User;
}

const Profile: FC<Props> = (props) => {
  const { className, user } = props;
  const { t } = useUsersTranslations();

  return (
    <div className={classNames('profile', className)}>
      <div className='d-flex flex-row me-2'>
        <div className='photo me-2'>
          {user.facePhoto && (
            <NetworkImage
              src={getAbsoluteFacePhotoFilePath(user.facePhoto.name)}
              alt={user.facePhoto.name}
            />
          )}
          {!user.facePhoto && (
            <div className='no-photo'>
              <span className='fs-6'>{t('no_photo')}</span>
            </div>
          )}
          <span className='mt-2'>{t('face_photo')}</span>
        </div>
        <div className='photo'>
          {user.fullHeightPhoto && (
            <NetworkImage
              src={getAbsoluteFullHeightPhotoFilePath(user.fullHeightPhoto.name)}
              alt={user.fullHeightPhoto.name}
            />
          )}
          {!user.fullHeightPhoto && (
            <div className='no-photo'>
              <span className='fs-6'>{t('no_photo')}</span>
            </div>
          )}
          <span className='mt-2'>{t('full_height_photo')}</span>
        </div>
      </div>
      <div className='d-flex flex-grow-1'>
        <ListGroup className='flex-grow-1' variant='flush'>
          <ListGroup.Item className='d-flex justify-content-between'>
            <b>{t('roles')}: </b>
            <span>{user.roles.map((role) => t(role)).join(', ')}</span>
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-between'>
            <b>{t('email')}: </b>
            <span>{user.email}</span>
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-between'>
            <b>{t('clothing_size')}: </b>
            <span>{user.clothingSize?.title}</span>
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-between'>
            <b>{t('shoe_size')}: </b>
            <span>{user.shoeSize?.title}</span>
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-between'>
            <b>{t('height')}: </b>
            <span>{user.height} cm</span>
          </ListGroup.Item>
        </ListGroup>
      </div>
    </div>
  );
};

export default Profile;
