import { useAppDispatch, useAppSelector } from 'core/redux-store';
import { useMutation } from 'react-query';
import { ColorsClient } from 'api/clients';
import { resetColorToDelete } from '../slice';
import { showSuccessToast } from 'core/toast';
import useColorsTranslations from './useColorsTranslations';
import { useNavigate } from 'react-router-dom';
import { selectColorToDelete } from '../selectors';
import { updateColors } from 'core/redux-store/global/slice';

const useColorDelete = () => {
  const { t } = useColorsTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const colorToDelete = useAppSelector(selectColorToDelete);

  const { mutateAsync: deleteColor, isLoading } = useMutation(
    async () => (colorToDelete ? ColorsClient.deleteColor(colorToDelete.id) : undefined),
    {
      onSuccess: () => {
        dispatch(updateColors());
        dispatch(resetColorToDelete());
        showSuccessToast(t('color_is_successfully_deleted'));
        navigate('/colors');
      },
    }
  );

  return { isLoading, deleteColor };
};

export default useColorDelete;
