import React, { FC } from 'react';
import { Base64File } from 'api/types';
import { FileCard, Dropzone } from 'components';

interface Props {
  onPhotoChange: (photo: Base64File | null) => void;
  photo: Base64File | null;
  className?: string;
  isDisabled?: boolean;
}

const PhotoControl: FC<Props> = (props) => {
  const { onPhotoChange, photo, className, isDisabled = false } = props;

  const handleOnAdd = (photoToAdd: Base64File) => {
    onPhotoChange(photoToAdd);
  };

  const handleOnRemove = () => {
    onPhotoChange(null);
  };

  return (
    <div className={className}>
      {!!photo && (
        <FileCard
          className='mb-2'
          name={photo.name}
          size={photo.size}
          src={photo.value}
          onRemove={handleOnRemove}
        />
      )}
      {!isDisabled && (
        <Dropzone multiple={false} onAdd={([photoToAdd]) => handleOnAdd(photoToAdd)} />
      )}
    </div>
  );
};

export default PhotoControl;
