import { User as ApiUser } from 'api/clients/users/types';
import { User } from './types';
import moment from 'moment';

export const mapUser = (user: ApiUser): User => ({
  ...user,
  isAdmin: user.roles.includes('ROLE_ADMIN'),
  updated: user.updated ? moment(user.updated).format('YYYY-MM-DD HH:mm:ss') : null,
  created: moment(user.created).format('YYYY-MM-DD HH:mm:ss'),
});
