import React, { FC, useMemo } from 'react';
import { useOrders, useOrdersTranslations } from '../hooks';
import { DataTable, TimerCell } from 'components';
import { CellProps, Column } from 'react-table';
import { Order } from '../types';
import { ActionsCell, PriceCell, StatusCell } from '../components';
import { OrdersFilter } from 'api/clients/orders/types';

interface Props {
  filter?: OrdersFilter;
}

const OrdersTable: FC<Props> = (props) => {
  const { filter } = props;
  const { t } = useOrdersTranslations();
  const { orders } = useOrders(filter);

  const columns = useMemo<Column<Order>[]>(
    () => [
      {
        Header: t('number'),
        accessor: 'number',
      },
      {
        Header: t('product'),
        accessor: 'product',
        Cell: (cell: CellProps<any>) => cell.row.original.product.title,
      },
      {
        Header: t('price'),
        accessor: 'price',
        Cell: PriceCell,
      },
      {
        Header: t('user'),
        accessor: 'user',
        Cell: (cell: CellProps<any>) => cell.row.original.user.email,
      },
      {
        Header: t('status'),
        Cell: StatusCell,
        accessor: 'status',
      },
      {
        Header: t('created'),
        accessor: 'created',
      },
      {
        Header: t('time_left'),
        accessor: 'completionDate',
        Cell: TimerCell,
      },
      {
        Header: t('actions'),
        id: 'actions',
        disableSortBy: true,
        Cell: ActionsCell,
      },
    ],
    [t]
  );

  return (
    <DataTable
      sortBy={[{ id: 'created', desc: true }]}
      hiddenColumns={filter?.userId ? ['user'] : undefined}
      data={orders}
      columns={columns}
    />
  );
};

export default OrdersTable;
