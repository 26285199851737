import { useAppDispatch, useAppSelector } from 'core/redux-store';
import { useMutation } from 'react-query';
import { UsersClient } from 'api/clients';
import { resetUserToDelete } from '../slice';
import { showSuccessToast } from 'core/toast';
import useUsersTranslations from './useUsersTranslations';
import { useNavigate } from 'react-router-dom';
import { selectUserToDelete } from '../selectors';
import { updateUsers } from 'core/redux-store/global/slice';

const useUserDelete = () => {
  const { t } = useUsersTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userToDelete = useAppSelector(selectUserToDelete);

  const { mutateAsync: deleteUser, isLoading } = useMutation(
    async () => (userToDelete ? UsersClient.deleteUser(userToDelete.id) : undefined),
    {
      onSuccess: () => {
        dispatch(updateUsers());
        dispatch(resetUserToDelete());
        showSuccessToast(t('user_is_successfully_deleted'));
        navigate('/users');
      },
    }
  );

  return { isLoading, deleteUser };
};

export default useUserDelete;
