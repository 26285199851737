import React, { FC, useCallback } from 'react';
import { useFaqDelete, useFaqsTranslations } from '../hooks';
import { useAppSelector } from 'core/redux-store';
import { selectFaqToDelete } from '../selectors';
import { resetFaqToDelete } from '../slice';
import { useDispatch } from 'react-redux';
import { ConfirmationModal } from 'components';

const FaqDeleteConfirmation: FC = () => {
  const { t } = useFaqsTranslations();
  const dispatch = useDispatch();

  const { deleteFaq, isLoading } = useFaqDelete();
  const faqToDelete = useAppSelector(selectFaqToDelete);

  const handleOnConfirmationModalClose = useCallback(() => {
    dispatch(resetFaqToDelete());
  }, [dispatch]);

  return (
    <ConfirmationModal
      isActionsDisabled={isLoading}
      isVisible={!!faqToDelete}
      content={faqToDelete?.title}
      onClose={handleOnConfirmationModalClose}
      title={t('are_you_sure_want_to_delete_this_faq')}
      confirmLabel={t('delete')}
      onConfirm={deleteFaq}
    />
  );
};

export default FaqDeleteConfirmation;
