import {
  ShoppingItem as ApiShoppingItem,
  SaveShoppingItemRequest,
} from 'api/clients/shopping-items/types';
import { ShoppingItem, ShoppingItemFormData } from './types';
import moment from 'moment';

export const mapShoppingItem = (shoppingItem: ApiShoppingItem): ShoppingItem => ({
  ...shoppingItem,
  updated: shoppingItem.updated ? moment(shoppingItem.updated).format('YYYY-MM-DD HH:mm:ss') : null,
  created: moment(shoppingItem.created).format('YYYY-MM-DD HH:mm:ss'),
});

export const mapShoppingItemFormData = (shoppingItem: ShoppingItem): ShoppingItemFormData => ({
  tips: shoppingItem.tips.join('\n'),
  enabled: shoppingItem.enabled,
  link: shoppingItem.link,
  photo: null,
});

export const mapSaveShoppingItemRequest = (
  formData: ShoppingItemFormData
): SaveShoppingItemRequest => ({
  ...formData,
  tips: formData.tips.split('\n'),
  photo: formData.photo?.value ?? null,
});
