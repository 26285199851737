import { Revision, RevisionFormData } from '../types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { RevisionsClient } from 'api/clients';
import { mapRevisionFormData, mapSaveRevisionRequest } from '../map';
import { useAppDispatch } from 'core/redux-store';
import { showSuccessToast } from 'core/toast';
import useRevisionsTranslations from './useRevisionsTranslations';
import { useNavigate } from 'react-router-dom';
import { updateItems, updateOrders } from 'core/redux-store/global/slice';

const revisionShape = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
});

const getSchema = () => revisionShape.required();

const useRevisionForm = (orderNumber: string, revision?: Revision) => {
  const { t } = useRevisionsTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { handleSubmit, control } = useForm<RevisionFormData>({
    resolver: yupResolver(getSchema()),
    defaultValues: revision ? mapRevisionFormData(revision) : undefined,
  });

  const { mutateAsync, isLoading } = useMutation(
    async (formData: RevisionFormData) => {
      const request = mapSaveRevisionRequest(formData);
      await RevisionsClient.addRevision({ ...request, revisionOrderNumber: orderNumber });
    },
    {
      onSuccess: () => {
        dispatch(updateOrders());
        dispatch(updateItems());
        showSuccessToast(t('revision_is_successfully_saved'));
        navigate('/orders');
      },
    }
  );

  const save = async (formData: RevisionFormData) => {
    await mutateAsync(formData);
  };

  return { handleSubmit, save, control, isLoading };
};

export default useRevisionForm;
