import { Testimonial, TestimonialFormData } from '../types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { TestimonialsClient } from 'api/clients';
import { useEffect } from 'react';
import { mapTestimonialFormData, mapSaveTestimonialRequest } from '../map';
import { useAppDispatch } from 'core/redux-store';
import { showSuccessToast } from 'core/toast';
import { useNavigate } from 'react-router-dom';
import useTestimonialsTranslations from './useTestimonialsTranslations';
import { updateTestimonials } from 'core/redux-store/global/slice';

const photoShape = yup.object().shape({
  name: yup.string().required(),
  size: yup.number().required(),
  value: yup.string().required(),
});

const testimonialShape = yup.object().shape({
  enabled: yup.boolean().required(),
  fullName: yup.string().required(),
  comment: yup.string().required(),
  photo: photoShape.nullable(),
});

const getSchema = () => testimonialShape.required();

const useTestimonialForm = (testimonial?: Testimonial) => {
  const { t } = useTestimonialsTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { handleSubmit, control, reset } = useForm<TestimonialFormData>({
    resolver: yupResolver(getSchema()),
  });

  useEffect(() => {
    if (!testimonial) return;
    reset(mapTestimonialFormData(testimonial));
  }, [testimonial, reset]);

  const { mutateAsync, isLoading } = useMutation(
    async (formData: TestimonialFormData) => {
      const request = mapSaveTestimonialRequest(formData);
      if (!testimonial) await TestimonialsClient.addTestimonial(request);
      else await TestimonialsClient.editTestimonial(testimonial.id, request);
    },
    {
      onSuccess: () => {
        dispatch(updateTestimonials());
        showSuccessToast(t('testimonial_is_successfully_saved'));
        navigate('/testimonials');
      },
    }
  );

  const save = async (formData: TestimonialFormData) => {
    await mutateAsync(formData);
  };

  return { handleSubmit, save, control, isLoading };
};

export default useTestimonialForm;
