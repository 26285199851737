import { OneTimer as ApiOneTimer, SaveOneTimerRequest } from 'api/clients/one-timers/types';
import { OneTimer, OneTimerFormData } from './types';
import moment from 'moment';
import { mapOutfit } from 'features/outfits/map';

export const mapOneTimer = (oneTimer: ApiOneTimer): OneTimer => ({
  ...oneTimer,
  outfit: mapOutfit(oneTimer.outfit),
  date: moment(oneTimer.date).format('YYYY-MM-DD HH:mm:ss'),
  updated: oneTimer.updated ? moment(oneTimer.updated).format('YYYY-MM-DD HH:mm:ss') : null,
  created: moment(oneTimer.created).format('YYYY-MM-DD HH:mm:ss'),
});

export const mapOneTimerFormData = (oneTimer: OneTimer): OneTimerFormData => ({
  itemsIds: oneTimer.outfit.outfitItems.map((outfitItem) => outfitItem.item.id),
  shoppingItemsIds: oneTimer.outfit.shoppingItems.map((shoppingItem) => shoppingItem.id),
  photo: null,
});

export const mapSaveOneTimerRequest = (
  formData: OneTimerFormData
): Omit<SaveOneTimerRequest, 'oneTimerOrderNumber'> => ({
  ...formData,
  photo: formData.photo?.value ?? null,
});
