import { useQuery } from 'react-query';
import { OrdersClient } from 'api/clients';
import { mapOrder } from '../map';
import { useAppSelector } from 'core/redux-store';
import { selectOrdersLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (number: string, lastUpdated: number) => {
  return ['order', number, lastUpdated];
};

const useOrder = (number: string) => {
  const lastUpdated = useAppSelector(selectOrdersLastUpdated);
  const getOrderFn = () => OrdersClient.getOrder(number);
  const { isLoading, data } = useQuery(getQueryKey(number, lastUpdated), getOrderFn);
  return { isLoading, order: data ? mapOrder(data.data) : null };
};

export default useOrder;
