import React, { FC, useMemo } from 'react';
import { useColors, useColorsTranslations } from '../hooks';
import { DataTable, UpdatedCell, StatusCell } from 'components';
import { Column } from 'react-table';
import { Color } from '../types';
import { ActionsCell, CodeCell } from '../components';

const ColorsTable: FC = () => {
  const { t } = useColorsTranslations();
  const { colors } = useColors();

  const columns = useMemo<Column<Color>[]>(
    () => [
      {
        Header: t('id'),
        accessor: 'id',
      },
      {
        Header: t('title'),
        accessor: 'title',
      },
      {
        Header: t('code'),
        accessor: 'code',
        Cell: CodeCell,
      },
      {
        Header: t('status'),
        Cell: StatusCell,
        accessor: 'enabled',
      },
      {
        Header: t('updated'),
        Cell: UpdatedCell,
        accessor: 'updated',
      },
      {
        Header: t('created'),
        accessor: 'created',
      },
      {
        Header: t('actions'),
        id: 'actions',
        disableSortBy: true,
        Cell: ActionsCell,
      },
    ],
    [t]
  );

  return <DataTable data={colors} columns={columns} />;
};

export default ColorsTable;
