import React, { FC, useMemo } from 'react';
import { useTestimonials, useTestimonialsTranslations } from '../hooks';
import { DataTable, StatusCell, UpdatedCell } from 'components';
import { Column } from 'react-table';
import { Testimonial } from '../types';
import { ActionsCell, PhotoCell } from '../components';

const TestimonialsTable: FC = () => {
  const { t } = useTestimonialsTranslations();
  const { testimonials } = useTestimonials();

  const columns = useMemo<Column<Testimonial>[]>(
    () => [
      {
        Header: t('id'),
        accessor: 'id',
      },
      {
        Header: t('photo'),
        Cell: PhotoCell,
        disableSortBy: true,
        accessor: 'photo',
      },
      {
        Header: t('full_name'),
        accessor: 'fullName',
      },
      {
        Header: t('comment'),
        accessor: 'comment',
      },
      {
        Header: t('status'),
        accessor: 'enabled',
        Cell: StatusCell,
      },
      {
        Header: t('updated'),
        Cell: UpdatedCell,
        accessor: 'updated',
      },
      {
        Header: t('created'),
        accessor: 'created',
      },
      {
        Header: t('actions'),
        id: 'actions',
        disableSortBy: true,
        Cell: ActionsCell,
      },
    ],
    [t]
  );

  return <DataTable data={testimonials} columns={columns} />;
};

export default TestimonialsTable;
