import { useAppSelector } from 'core/redux-store';
import { useQuery } from 'react-query';
import { SubscriptionsClient } from 'api/clients';
import { mapSubscription } from '../map';
import { selectSubscriptionsLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (lastUpdated: number) => {
  return ['subscriptions', lastUpdated];
};

const useSubscriptions = () => {
  const lastUpdated = useAppSelector(selectSubscriptionsLastUpdated);

  const { isLoading, data } = useQuery(
    getQueryKey(lastUpdated),
    SubscriptionsClient.getSubscriptions
  );

  return {
    isLoading,
    subscriptions: data?.data.map(mapSubscription) ?? [],
    subscriptionsCount: data?.total ?? 0,
  };
};

export default useSubscriptions;
