import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { OneTimerForm } from 'features/one-timers/hoc';
import { useOrder } from 'features/orders/hooks';
import { OneTimerOrder } from 'features/orders/types';
import { useOneTimer } from 'features/one-timers/hooks';

const OneTimerDetailsPage: FC = () => {
  const params = useParams();
  const orderNumber = params.number as string;
  const { order, isLoading: isOrderLoading } = useOrder(orderNumber);
  const { oneTimer, isLoading: isOneTimerLoading } = useOneTimer(orderNumber);

  return (
    <Container>
      {!!order && !isOrderLoading && !isOneTimerLoading && !!oneTimer && (
        <OneTimerForm oneTimer={oneTimer ?? undefined} oneTimerOrder={order as OneTimerOrder} />
      )}
    </Container>
  );
};

export default OneTimerDetailsPage;
