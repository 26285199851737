import React, { FC, useCallback, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Color, ColorFormData } from '../types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useColors, useColorsTranslations } from '../hooks';
import { ColorOption, FormGroup } from 'components';
import Select from 'react-select';

interface Props {
  control: Control<ColorFormData>;
  className?: string;
  color?: Color;
}

const MetaForm: FC<Props> = (props) => {
  const { control, className, color } = props;
  const { t } = useColorsTranslations();
  const { colors, isLoading: isColorsLoading } = useColors();

  const colorsOptions = useMemo(
    () => colors.map((option) => ({ value: option.id, label: option.title, code: option.code })),
    [colors]
  );

  const findColorsOptions = useCallback(
    (ids: number[]) => colorsOptions.filter((option) => ids.includes(option.value)),
    [colorsOptions]
  );

  return (
    <Card className={className}>
      <Card.Header>
        <h5 className='card-header-title'>{t('meta')}</h5>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Controller
              defaultValue={false}
              control={control}
              name='enabled'
              render={({ field: { value, ...rest }, fieldState: { error } }) => (
                <FormGroup
                  row
                  help={t('determine_if_color_is_active')}
                  error={error?.message}
                  label={t('enabled')}
                >
                  <Form.Switch isInvalid={!!error} checked={value} {...rest} />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
        {!!color && (
          <Row className='mt-4'>
            <Col>
              <FormGroup label={t('matched_colors')}>
                <Select
                  isDisabled
                  isMulti
                  isLoading={isColorsLoading}
                  formatOptionLabel={(option) => <ColorOption {...option} />}
                  options={colorsOptions}
                  value={findColorsOptions(color.matchedColors.map((option) => option.id))}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default MetaForm;
