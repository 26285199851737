import { useAppSelector } from 'core/redux-store';
import { useQuery } from 'react-query';
import { TestimonialsClient } from 'api/clients';
import { mapTestimonial } from '../map';
import { selectTestimonialsLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (lastUpdated: number) => {
  return ['testimonials', lastUpdated];
};

const useTestimonials = () => {
  const lastUpdated = useAppSelector(selectTestimonialsLastUpdated);

  const { isLoading, data } = useQuery(
    getQueryKey(lastUpdated),
    TestimonialsClient.getTestimonials
  );

  return {
    isLoading,
    testimonials: data?.data.map(mapTestimonial) ?? [],
    testimonialsCount: data?.total ?? 0,
  };
};

export default useTestimonials;
