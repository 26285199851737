import React, { FC, useMemo } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { useRevisionForm, useRevisionsTranslations } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { DetailsForm, MetaForm, ItemsForm } from '../components';
import { RevisionOrder } from 'features/orders/types';
import { ItemsFilter } from 'api/clients/items/types';
import { useItems } from 'features/items/hooks';
import { Revision } from '../types';
import { Item } from 'features/items/types';

interface Props {
  revisionOrder: RevisionOrder;
  revision?: Revision;
}

const RevisionForm: FC<Props> = (props) => {
  const { revisionOrder, revision } = props;
  const { t } = useRevisionsTranslations();
  const navigate = useNavigate();

  const { control, handleSubmit, save, isLoading } = useRevisionForm(
    revisionOrder.number,
    revision
  );

  const itemsFilter = useMemo<ItemsFilter>(
    () => ({ userId: revisionOrder.user.id, statuses: ['active', 'approved'] }),
    [revisionOrder.user.id]
  );

  const { items } = useItems(itemsFilter);
  const revisionItems = useMemo<Item[]>(
    () => (revision ? revision.revisionItems.map((revisionItem) => revisionItem.item) : items),
    [items, revision]
  );

  return (
    <>
      <div className='page-header'>
        <Row className='align-items-center'>
          <Col>
            <h2 className='page-header-title'>
              {revision ? t('revision_details') : t('add_revision')}
            </h2>
          </Col>
          <Col lg='auto'>
            <Button
              size='sm'
              onClick={() => navigate('/orders')}
              disabled={isLoading}
              variant='white'
            >
              {t('back_to_orders_list')}
            </Button>
            {!revision && (
              <Button
                className='ms-2'
                size='sm'
                onClick={handleSubmit(save)}
                disabled={isLoading}
                variant='primary'
              >
                {t('save_revision')}
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <Row className='mb-3'>
        <Col lg={8}>
          <DetailsForm isDisabled={!!revision} className='mb-3' control={control} />
          <ItemsForm isDisabled={!!revision} items={revisionItems} control={control} />
        </Col>
        <Col lg={4}>
          <MetaForm revisionOrder={revisionOrder} />
        </Col>
      </Row>
    </>
  );
};

export default RevisionForm;
