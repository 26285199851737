import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Faq } from './types';
import { logout } from 'features/auth/slice';

interface State {
  faqToDelete: Faq | null;
}

const initialState: State = {
  faqToDelete: null,
};

const faqsSlice = createSlice({
  name: 'faqs',
  initialState,
  reducers: {
    setFaqToDelete: (state, action: PayloadAction<{ faq: Faq }>) => {
      state.faqToDelete = action.payload.faq;
    },
    resetFaqToDelete: (state) => {
      state.faqToDelete = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const { setFaqToDelete, resetFaqToDelete } = faqsSlice.actions;
export const { reducer } = faqsSlice;
