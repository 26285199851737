import React, { FC, useMemo } from 'react';
import { useUsers, useUsersTranslations } from '../hooks';
import { DataTable, UpdatedCell } from 'components';
import { Column } from 'react-table';
import { User } from '../types';
import { RolesCell, StatusCell, ActionsCell } from '../components';

const UsersTable: FC = () => {
  const { t } = useUsersTranslations();
  const { users } = useUsers();

  const columns = useMemo<Column<User>[]>(
    () => [
      {
        Header: t('id'),
        accessor: 'id',
      },
      {
        Header: t('email'),
        accessor: 'email',
      },
      {
        Header: t('status'),
        Cell: StatusCell,
        accessor: 'status',
      },
      {
        Header: t('roles'),
        Cell: RolesCell,
        accessor: 'roles',
      },
      {
        Header: t('updated'),
        Cell: UpdatedCell,
        accessor: 'updated',
      },
      {
        Header: t('created'),
        accessor: 'created',
      },
      {
        Header: t('actions'),
        id: 'actions',
        disableSortBy: true,
        Cell: ActionsCell,
      },
    ],
    [t]
  );

  return <DataTable data={users} columns={columns} />;
};

export default UsersTable;
