import { Subscription, SubscriptionFormData } from '../types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { SubscriptionsClient } from 'api/clients';
import { useEffect } from 'react';
import { mapSubscriptionFormData, mapSaveSubscriptionRequest } from '../map';
import { useAppDispatch } from 'core/redux-store';
import { showSuccessToast } from 'core/toast';
import useSubscriptionsTranslations from './useSubscriptionsTranslations';
import { useNavigate } from 'react-router-dom';
import { updateSubscriptions } from 'core/redux-store/global/slice';

const subscriptionShape = yup.object().shape({
  sku: yup.string().required(),
  enabled: yup.boolean().required(),
  title: yup.string().required(),
  description: yup.string().required(),
  type: yup.string().required(),
  duration: yup.string().required(),
  price: yup.number().positive().required(),
  currency: yup.string().required(),
});

const getSchema = () => subscriptionShape.required();

const useSubscriptionForm = (subscription?: Subscription) => {
  const { t } = useSubscriptionsTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { handleSubmit, control, reset, formState } = useForm<SubscriptionFormData>({
    resolver: yupResolver(getSchema()),
  });

  console.log('errors', formState.errors);

  useEffect(() => {
    if (!subscription) return;
    reset(mapSubscriptionFormData(subscription));
  }, [subscription, reset]);

  const { mutateAsync, isLoading } = useMutation(
    async (formData: SubscriptionFormData) => {
      const request = mapSaveSubscriptionRequest(formData);
      if (!subscription) await SubscriptionsClient.addSubscription(request);
      else await SubscriptionsClient.editSubscription(subscription.id, request);
    },
    {
      onSuccess: () => {
        dispatch(updateSubscriptions());
        showSuccessToast(t('subscription_is_successfully_saved'));
        navigate('/subscriptions');
      },
    }
  );

  const save = async (formData: SubscriptionFormData) => {
    await mutateAsync(formData);
  };

  return { handleSubmit, save, control, isLoading };
};

export default useSubscriptionForm;
