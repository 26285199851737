import { RootState } from 'core/redux-store';
import { Profile } from './types';

export const selectToken = (state: RootState): string | null => {
  return state.auth.token;
};

export const selectIsAuthenticated = (state: RootState): boolean => {
  return !!state.auth.token;
};

export const selectProfile = (state: RootState): Profile | null => {
  return state.auth.profile;
};

export const selectIsLoggedIn = (state: RootState): boolean => {
  return selectIsAuthenticated(state) && !!selectProfile(state);
};
