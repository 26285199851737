import { RevisionPlan, RevisionPlanFormData } from '../types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { RevisionPlansClient } from 'api/clients';
import { useEffect } from 'react';
import { mapSaveRevisionPlanRequest, mapRevisionPlanFormData } from '../map';
import { useAppDispatch } from 'core/redux-store';
import { showSuccessToast } from 'core/toast';
import useRevisionPlansTranslations from './useRevisionPlansTranslations';
import { useNavigate } from 'react-router-dom';
import { updateRevisionPlans } from 'core/redux-store/global/slice';

const revisionPlanShape = yup.object().shape({
  enabled: yup.boolean().required(),
  title: yup.string().required(),
  price: yup.number().positive().required(),
  currency: yup.string().required(),
});

const getSchema = () => revisionPlanShape.required();

const useRevisionPlanForm = (revisionPlan?: RevisionPlan) => {
  const { t } = useRevisionPlansTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { handleSubmit, control, reset } = useForm<RevisionPlanFormData>({
    resolver: yupResolver(getSchema()),
  });

  useEffect(() => {
    if (!revisionPlan) return;
    reset(mapRevisionPlanFormData(revisionPlan));
  }, [revisionPlan, reset]);

  const { mutateAsync, isLoading } = useMutation(
    async (formData: RevisionPlanFormData) => {
      const request = mapSaveRevisionPlanRequest(formData);
      if (!revisionPlan) await RevisionPlansClient.addRevisionPlan(request);
      else await RevisionPlansClient.editRevisionPlan(revisionPlan.id, request);
    },
    {
      onSuccess: () => {
        dispatch(updateRevisionPlans());
        showSuccessToast(t('revision_plan_is_successfully_saved'));
        navigate('/revision-plans');
      },
    }
  );

  const save = async (formData: RevisionPlanFormData) => {
    await mutateAsync(formData);
  };

  return { handleSubmit, save, control, isLoading };
};

export default useRevisionPlanForm;
