import {
  Subscription as ApiSubscription,
  SaveSubscriptionRequest,
} from 'api/clients/subscriptions/types';
import { Subscription, SubscriptionFormData } from './types';
import moment from 'moment';

export const mapSubscription = (subscription: ApiSubscription): Subscription => ({
  ...subscription,
  updated: subscription.updated ? moment(subscription.updated).format('YYYY-MM-DD HH:mm:ss') : null,
  created: moment(subscription.created).format('YYYY-MM-DD HH:mm:ss'),
});

export const mapSubscriptionFormData = (subscription: Subscription): SubscriptionFormData => ({
  sku: subscription.sku,
  title: subscription.title,
  enabled: subscription.enabled,
  description: subscription.description,
  duration: subscription.duration,
  type: subscription.type,
  currency: subscription.currency,
  price: subscription.price,
});

export const mapSaveSubscriptionRequest = (
  formData: SubscriptionFormData
): SaveSubscriptionRequest => formData;
