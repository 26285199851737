import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Order } from '../types';
import { useOrdersTranslations } from '../hooks';
import { EyeFill, PencilFill } from 'react-bootstrap-icons';

const ActionsCell: FC<CellProps<Order>> = (props) => {
  const { row } = props;
  const number = row.original.number;
  const product = row.original.product;
  const status = row.original.status;

  const navigate = useNavigate();
  const { t } = useOrdersTranslations();

  const handleOnFulfillPress = () => {
    if (product.type === 'revision') {
      navigate(`/orders/${number}/add-revision`);
    }

    if (product.type === 'oneTimer') {
      navigate(`/orders/${number}/add-one-timer`);
    }

    if (product.type === 'weekly') {
      navigate(`/orders/${number}/add-weekly-plan`);
    }
  };

  const handleOnViewPress = () => {
    if (product.type === 'revision') {
      navigate(`/orders/${number}/revision-details`);
    }

    if (product.type === 'oneTimer') {
      navigate(`/orders/${number}/one-timer-details`);
    }

    if (product.type === 'weekly') {
      navigate(`/orders/${number}/weekly-plan-details`);
    }
  };

  if (status === 'completed') {
    return (
      <Button onClick={handleOnViewPress} size='sm' variant='white'>
        <EyeFill className='me-2' />
        {t('view')}
      </Button>
    );
  }

  return (
    <Button disabled={status === 'unpaid'} onClick={handleOnFulfillPress} size='sm' variant='white'>
      <PencilFill className='me-2' />
      {t('fulfill')}
    </Button>
  );
};

export default ActionsCell;
