import React, { FC, useMemo } from 'react';
import { useFaqs, useFaqsTranslations } from '../hooks';
import { DataTable, UpdatedCell, StatusCell } from 'components';
import { Column } from 'react-table';
import { Faq } from '../types';
import { ActionsCell } from '../components';

const FaqsTable: FC = () => {
  const { t } = useFaqsTranslations();
  const { faqs } = useFaqs();

  const columns = useMemo<Column<Faq>[]>(
    () => [
      {
        Header: t('id'),
        accessor: 'id',
      },
      {
        Header: t('title'),
        accessor: 'title',
      },
      {
        Header: t('status'),
        Cell: StatusCell,
        accessor: 'enabled',
      },
      {
        Header: t('updated'),
        Cell: UpdatedCell,
        accessor: 'updated',
      },
      {
        Header: t('created'),
        accessor: 'created',
      },
      {
        Header: t('actions'),
        id: 'actions',
        disableSortBy: true,
        Cell: ActionsCell,
      },
    ],
    [t]
  );

  return <DataTable data={faqs} columns={columns} />;
};

export default FaqsTable;
