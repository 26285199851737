import { FC } from 'react';
import { CellProps } from 'react-table';
import { Product } from '../types';
import { formatMoney } from 'utils/currency';
import { useTranslation } from 'react-i18next';

const PriceCell: FC<CellProps<Product, number | null>> = (props) => {
  const { value: price, row } = props;
  const currency = row.original.currency;
  const { t } = useTranslation('common');

  if (!price || !currency) return t('none');
  return <>{formatMoney(price, currency)}</>;
};

export default PriceCell;
