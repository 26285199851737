import React, { FC, useMemo } from 'react';
import { useOutfits, useOutfitsTranslations } from '../hooks';
import { DataTable, UpdatedCell } from 'components';
import { CellProps, Column } from 'react-table';
import { Outfit } from '../types';
import { CreatedByCell, EnabledFromCell, PhotoCell } from '../components';
import { OutfitsFilter } from 'api/clients/outfits/types';

interface Props {
  filter: OutfitsFilter;
}

const OutfitsTable: FC<Props> = (props) => {
  const { filter } = props;
  const { t } = useOutfitsTranslations();
  const { outfits } = useOutfits(filter);

  const columns = useMemo<Column<Outfit>[]>(
    () => [
      {
        Header: t('id'),
        accessor: 'id',
      },
      {
        Header: t('photo'),
        Cell: PhotoCell,
        disableSortBy: true,
        accessor: 'photo',
      },
      {
        Header: t('occasion'),
        accessor: 'occasion',
        Cell: (cell: CellProps<any>) => cell.row.original.occasion?.title ?? t('none'),
      },
      {
        Header: t('is_created_by_sos'),
        accessor: 'isCreatedBySos',
        Cell: CreatedByCell,
      },
      {
        Header: t('enabled_from'),
        Cell: EnabledFromCell,
        accessor: 'enabledFrom',
      },
      {
        Header: t('updated'),
        Cell: UpdatedCell,
        accessor: 'updated',
      },
      {
        Header: t('created'),
        accessor: 'created',
      },
    ],
    [t]
  );

  return <DataTable data={outfits} columns={columns} />;
};

export default OutfitsTable;
