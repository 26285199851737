import httpClient from '../../httpClient';
import { ListResponse, DataResponse } from '../../types';
import { Subscription, SaveSubscriptionRequest } from './types';

const baseUrl = '/admin/subscriptions';

export const getSubscriptions = () => {
  return httpClient.get<void, ListResponse<Subscription>>(baseUrl);
};

export const getSubscription = (id: number) => {
  return httpClient.get<void, DataResponse<Subscription>>(`${baseUrl}/${id}`);
};

export const addSubscription = async (request: SaveSubscriptionRequest) => {
  return httpClient.post<SaveSubscriptionRequest, DataResponse<Subscription>>(baseUrl, request);
};

export const editSubscription = async (id: number, request: SaveSubscriptionRequest) => {
  return httpClient.put<SaveSubscriptionRequest, DataResponse<Subscription>>(
    `${baseUrl}/${id}`,
    request
  );
};

export const deleteSubscription = (id: number) => {
  return httpClient.delete(`${baseUrl}/${id}`);
};
