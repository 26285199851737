import { useAppDispatch, useAppSelector } from 'core/redux-store';
import { useMutation } from 'react-query';
import { TestimonialsClient } from 'api/clients';
import { resetTestimonialToDelete } from '../slice';
import { showSuccessToast } from 'core/toast';
import useTestimonialsTranslations from './useTestimonialsTranslations';
import { useNavigate } from 'react-router-dom';
import { selectTestimonialToDelete } from '../selectors';
import { updateTestimonials } from 'core/redux-store/global/slice';

const useTestimonialDelete = () => {
  const { t } = useTestimonialsTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const testimonialToDelete = useAppSelector(selectTestimonialToDelete);

  const { mutateAsync: deleteTestimonial, isLoading } = useMutation(
    async () =>
      testimonialToDelete
        ? TestimonialsClient.deleteTestimonial(testimonialToDelete.id)
        : undefined,
    {
      onSuccess: () => {
        dispatch(updateTestimonials());
        dispatch(resetTestimonialToDelete());
        showSuccessToast(t('testimonial_is_successfully_deleted'));
        navigate('/testimonials');
      },
    }
  );

  return { isLoading, deleteTestimonial };
};

export default useTestimonialDelete;
