import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { OneTimerForm } from 'features/one-timers/hoc';
import { useOrder } from 'features/orders/hooks';
import { OneTimerOrder } from 'features/orders/types';

const OneTimerAddPage: FC = () => {
  const params = useParams();
  const orderNumber = params.number as string;
  const { order, isLoading } = useOrder(orderNumber);

  return (
    <Container>
      {!!order && !isLoading && <OneTimerForm oneTimerOrder={order as OneTimerOrder} />}
    </Container>
  );
};

export default OneTimerAddPage;
