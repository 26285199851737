import { useAppSelector } from 'core/redux-store';
import { useQuery } from 'react-query';
import { UsersClient } from 'api/clients';
import { mapUser } from '../map';
import { selectUsersLastUpdated } from 'core/redux-store/global/selectors';

export const getQueryKey = (lastUpdated: number) => {
  return ['users', lastUpdated];
};

const useUsers = () => {
  const lastUpdated = useAppSelector(selectUsersLastUpdated);
  const { isLoading, data } = useQuery(getQueryKey(lastUpdated), UsersClient.getUsers);
  return { isLoading, users: data?.data.map(mapUser) ?? [], usersCount: data?.total ?? 0 };
};

export default useUsers;
