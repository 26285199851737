import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { ShoppingItemForm } from 'features/shopping-items/hoc';

const ShoppingItemAddPage: FC = () => {
  return (
    <Container>
      <ShoppingItemForm />
    </Container>
  );
};

export default ShoppingItemAddPage;
