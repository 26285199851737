import React, { FC, useCallback } from 'react';
import { useSubscriptionDelete, useSubscriptionsTranslations } from '../hooks';
import { useAppSelector } from 'core/redux-store';
import { selectSubscriptionToDelete } from '../selectors';
import { resetSubscriptionToDelete } from '../slice';
import { useDispatch } from 'react-redux';
import { ConfirmationModal } from 'components';

const SubscriptionDeleteConfirmation: FC = () => {
  const { t } = useSubscriptionsTranslations();
  const dispatch = useDispatch();

  const { deleteSubscription, isLoading } = useSubscriptionDelete();
  const subscriptionToDelete = useAppSelector(selectSubscriptionToDelete);

  const handleOnConfirmationModalClose = useCallback(() => {
    dispatch(resetSubscriptionToDelete());
  }, [dispatch]);

  return (
    <ConfirmationModal
      isActionsDisabled={isLoading}
      isVisible={!!subscriptionToDelete}
      content={subscriptionToDelete?.title}
      onClose={handleOnConfirmationModalClose}
      title={t('are_you_sure_want_to_delete_this_subscription')}
      confirmLabel={t('delete')}
      onConfirm={deleteSubscription}
    />
  );
};

export default SubscriptionDeleteConfirmation;
