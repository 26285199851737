import httpClient from '../../httpClient';
import { DataResponse } from '../../types';
import { Revision, SaveRevisionRequest } from './types';

const baseUrl = '/admin/revisions';

export const getRevisionByOrderNumber = (number: string) => {
  return httpClient.get<void, DataResponse<Revision>>(`${baseUrl}/${number}`);
};

export const addRevision = async (request: SaveRevisionRequest) => {
  return httpClient.post<SaveRevisionRequest, DataResponse<Revision>>(baseUrl, request);
};
