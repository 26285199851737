import React, { FC, useCallback } from 'react';
import { useProductDelete, useProductsTranslations } from '../hooks';
import { useAppSelector } from 'core/redux-store';
import { selectProductToDelete } from '../selectors';
import { resetProductToDelete } from '../slice';
import { useDispatch } from 'react-redux';
import { ConfirmationModal } from 'components';

const ProductDeleteConfirmation: FC = () => {
  const { t } = useProductsTranslations();
  const dispatch = useDispatch();

  const { deleteProduct, isLoading } = useProductDelete();
  const productToDelete = useAppSelector(selectProductToDelete);

  const handleOnConfirmationModalClose = useCallback(() => {
    dispatch(resetProductToDelete());
  }, [dispatch]);

  return (
    <ConfirmationModal
      isActionsDisabled={isLoading}
      isVisible={!!productToDelete}
      content={productToDelete?.title}
      onClose={handleOnConfirmationModalClose}
      title={t('are_you_sure_want_to_delete_this_product')}
      confirmLabel={t('delete')}
      onConfirm={deleteProduct}
    />
  );
};

export default ProductDeleteConfirmation;
