import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ShoppingItem } from './types';
import { logout } from 'features/auth/slice';

interface State {
  shoppingItemToDelete: ShoppingItem | null;
}

const initialState: State = {
  shoppingItemToDelete: null,
};

const shoppingItemsSlice = createSlice({
  name: 'shoppingItems',
  initialState,
  reducers: {
    setShoppingItemToDelete: (state, action: PayloadAction<{ shoppingItem: ShoppingItem }>) => {
      state.shoppingItemToDelete = action.payload.shoppingItem;
    },
    resetShoppingItemToDelete: (state) => {
      state.shoppingItemToDelete = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const { setShoppingItemToDelete, resetShoppingItemToDelete } = shoppingItemsSlice.actions;
export const { reducer } = shoppingItemsSlice;
