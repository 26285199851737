import React, { FC, useCallback, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { OneTimerFormData } from '../types';
import { Card, Col, Row } from 'react-bootstrap';
import { useOneTimersTranslations } from '../hooks';
import { FormGroup, ImageOption } from 'components';
import Select from 'react-select';
import { useItems } from 'features/items/hooks';
import { OneTimerOrder } from 'features/orders/types';
import { useShoppingItems } from 'features/shopping-items/hooks';
import { getAbsoluteFilePath as getAbsoluteItemFilePath } from 'features/items/utils';
import { getAbsoluteFilePath as getAbsoluteShoppingItemFilePath } from 'features/shopping-items/utils';

interface Props {
  className?: string;
  control: Control<OneTimerFormData>;
  oneTimerOrder: OneTimerOrder;
  isDisabled: boolean;
}

const DetailsForm: FC<Props> = (props) => {
  const { control, className, oneTimerOrder, isDisabled } = props;
  const { t } = useOneTimersTranslations();

  const { items, isLoading: isItemsLoading } = useItems({ userId: oneTimerOrder.user.id });
  const { shoppingItems, isLoading: isShoppingItemsLoading } = useShoppingItems();

  const itemsOptions = useMemo(
    () =>
      items.map((item) => ({
        value: item.id,
        label: item.id.toString(),
        src: getAbsoluteItemFilePath(item.photo.name),
      })),
    [items]
  );

  const findItemsOptions = useCallback(
    (ids: number[]) => itemsOptions.filter((option) => ids.includes(option.value)),
    [itemsOptions]
  );

  const shoppingItemsOptions = useMemo(
    () =>
      shoppingItems.map((shoppingItem) => ({
        value: shoppingItem.id,
        label: shoppingItem.id.toString(),
        src: getAbsoluteShoppingItemFilePath(shoppingItem.photo.name),
      })),
    [shoppingItems]
  );

  const findShoppingItemsOptions = useCallback(
    (ids: number[]) => shoppingItemsOptions.filter((option) => ids.includes(option.value)),
    [shoppingItemsOptions]
  );

  return (
    <Card className={className}>
      <Card.Header>
        <h5 className='card-header-title'>{t('one_timer_details')}</h5>
      </Card.Header>
      <Card.Body>
        <Row className='mb-4'>
          <Col>
            <Controller
              defaultValue={[]}
              control={control}
              name='itemsIds'
              render={({ field: { value, onChange, ...rest }, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('items')}>
                  <Select
                    isDisabled={isDisabled}
                    isLoading={isItemsLoading}
                    isClearable
                    isMulti
                    formatOptionLabel={(option) => <ImageOption {...option} />}
                    placeholder={t('select_items')}
                    options={itemsOptions}
                    value={findItemsOptions(value)}
                    onChange={(options) => onChange(options.map((option) => option.value))}
                    {...rest}
                  />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Controller
              defaultValue={[]}
              control={control}
              name='shoppingItemsIds'
              render={({ field: { value, onChange, ...rest }, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('shopping_items')}>
                  <Select
                    isDisabled={isDisabled}
                    isMulti
                    isLoading={isShoppingItemsLoading}
                    isClearable
                    formatOptionLabel={(option) => <ImageOption {...option} />}
                    placeholder={t('select_shopping_items')}
                    options={shoppingItemsOptions}
                    value={findShoppingItemsOptions(value)}
                    onChange={(options) => onChange(options.map((option) => option.value))}
                    {...rest}
                  />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DetailsForm;
